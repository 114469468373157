

// ANGULAR CORE

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// ENVIRONMENT

import { environment } from "../environments/environment"


// CACHE BUSTING

import cacheBusting from '../../assets-cache-busting.json';

// TRANSLATION

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// https://github.com/ngx-translate/core
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n-jer/', '.json?_=' + cacheBusting['i18n']);
}


// AUTH

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
export function oAuthStorageFactory(): OAuthStorage { return localStorage; } // use localStorage instead of sessionStorage


// PLUGINS

import { AngularDraggableModule } from 'angular2-draggable';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxEchartsModule } from 'ngx-echarts';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';

// MAIN TABS
import { PageQuickSearchComponent } from './tabs/1-quicksearch/page-quicksearch.component';
import { PageSimilarNameComponent } from './tabs/2-byName/page-similarname.component';
import { PageSimilarLogoComponent } from './tabs/3-byLogo/page-similarlogo.component';
import { BrickComponent } from './tabs/4-advancedSearch/brick/brick.component';
import { PageAdvancedSearchComponent } from './tabs/4-advancedSearch/page-advancedSearch.component';
import { PageExploreComponent } from './tabs/5-explore/page-explore.component';
import { CompFacetsExplore } from './tabs/5bis-visu/comp-facets-explore/comp-facets-explore.component';
import { VisuComponent } from './tabs/5bis-visu/visu/visu.component';
import { PageVisuComponent } from './tabs/5bis-visu/page-visu.component';
import { PageReportsComponent } from './tabs/6-reports/page-reports.component';
import { PageCoverageComponent } from './tabs/7-coverage/page-coverage.component';

// COMMON COMPONENTS

import { CompFacetsSearch } from './common-components/facets-search/facets-search.component';
import { ImagedropComponent } from './common-components/comp-imagedrop/imagedrop.component';
import { ImageEditorComponent } from './common-components/comp-imageeditor/image-editor.component';
import { CompMenuBar } from './common-components/comp-nav-bar/comp-nav-bar.component';
import { QueryParams2humanComponent } from './common-components/comp-queryparams2human/queryParams2human.component';
import { CompResultsInfo } from './common-components/comp-results-info/comp-results-info.component';
import { CompJerSuggestMultiselectComponent } from './common-components/comp-jer-suggest-multiselect/comp-jer-suggest-multiselect.component';
import { ImageSliderComponent } from './common-components/comp-imageslider/image-slider.component';

// PAGES COMPONENTS

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HOCSearchComponent } from './hoc-search/search.component';
import { PageNotFoundComponent } from './pages/page-notfound/page-notfound.component';
import { FieldComponent } from './pages/page-details/doc-field/doc-field.component';
import { PageDetailsComponent } from './pages/page-details/page-details.component';
import { ExportComponent } from './pages/page-export/export/export.component';
import { PageExportComponent } from './pages/page-export/page-export.component';
import { PageHelpComponent } from './pages/page-help/page-help.component';
import { PageNewsComponent } from './pages/page-news/page-news.component';
import { JerPaginatorComponent } from './pages/page-results/jer-paginator/jer-paginator.component';
import { PageResultsComponent } from './pages/page-results/page-results.component';
import { ResultsListComponent } from './pages/page-results/results-list/results-list.component';
import { ResultsComponent } from './pages/page-results/results/results.component';
import { SearchResultsMenuComponent } from './pages/page-results/search-results-menu/search-results-menu.component';



// HTTP INTERCEPTOR (mainly for Giacomo... Not used?)

//import { AwsInterceptInterceptor } from './_services/aws-intercept.interceptor';



// W-ANGULAR AND PRIMENG

import {
	FFacetModule,
	WActionBarModule,
	WBlockUiModule,
	WButtonGroupModule,
	WButtonModule,
	WDrawerModule,
	WEditPanelModule,
	WFieldModule,
	WFilterModule,
	WInputAutocompleteModule,
	WInputBadgeModule,
	WInputCheckboxManyModule,
	WInputCheckboxOneModule,
	WInputRadioModule,
	WInputTextModule,
	WNotificationBarModule,
	WPageHeaderModule,
	WPageSectionModule,
	WSectionModule,
	WSelectManyModule,
	WSelectOneModule,
	WSidebarModule,
	WSlotModule,
	WStepModule,
	WStickyBarModule,
	WTableCommandsModule,
	WValidationPanelModule,
	WViewPanelModule,
} from '@wipo/w-angular';

import { DataViewModule } from 'primeng/dataview';
import { WInputDateModule } from '@wipo/w-angular/primeng';
import { TableSortPipe } from './_pipes/table-sort.pipe';

@NgModule({
	declarations: [
		AppComponent,
		CompFacetsSearch,
		CompMenuBar,
		CompResultsInfo,
		HOCSearchComponent,
		ImageEditorComponent,
		ImagedropComponent,
		ImageSliderComponent,
		QueryParams2humanComponent,
		CompJerSuggestMultiselectComponent,
		TableSortPipe,
		PageNotFoundComponent,
		PageQuickSearchComponent,
		PageSimilarNameComponent,
		PageAdvancedSearchComponent,
		PageSimilarLogoComponent,
		BrickComponent,
		PageExploreComponent,
		CompFacetsExplore,
		VisuComponent,
		PageVisuComponent,
		PageReportsComponent,
		PageCoverageComponent,
		FieldComponent,
		PageDetailsComponent,
		ExportComponent,
		PageExportComponent,
		PageHelpComponent,
		PageNewsComponent,
		JerPaginatorComponent,
		PageResultsComponent,
		ResultsListComponent,
		ResultsComponent,
		SearchResultsMenuComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		AngularDraggableModule,
		FFacetModule,
		WActionBarModule,
		WBlockUiModule,
		WButtonGroupModule,
		WButtonModule,
		WDrawerModule,
		WEditPanelModule,
		WFieldModule,
		WFilterModule,
		WInputAutocompleteModule,
		WInputBadgeModule,
		WInputCheckboxManyModule,
		WInputCheckboxOneModule,
		WInputDateModule,
		WInputRadioModule,
		WInputTextModule,
		WNotificationBarModule,
		WPageHeaderModule,
		WPageSectionModule,
		WSectionModule,
		WSelectManyModule,
		WSelectOneModule,
		WSidebarModule,
		WSlotModule,
		WStepModule,
		WStickyBarModule,
		WTableCommandsModule,
		WValidationPanelModule,
		WViewPanelModule,

		ClickOutsideModule,

		DataViewModule,

		// https://github.com/ngx-translate/core
		TranslateModule.forRoot({

			defaultLanguage: 'en',

			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),

		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),

		OAuthModule.forRoot({
			resourceServer: {
				allowedUrls: [
					environment.backendUrl
				],
				sendAccessToken: true
			}
		}),

		TippyModule.forRoot({
			defaultVariation: 'tooltip',
			variations: {
				tooltip: tooltipVariation,
				popper: popperVariation,
			}
		})
	],
	providers: [
		/*{
			provide: HTTP_INTERCEPTORS,
			useClass: AwsInterceptInterceptor,
			multi: true // false completely breaks the app, for some reason
		},*/
		{ provide: OAuthStorage, useFactory: oAuthStorageFactory }

	],
	bootstrap: [AppComponent]
})
export class AppModule { }
