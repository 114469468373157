import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WOption } from '@wipo/w-angular/shared';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { deepClone, canSearch } from 'src/app/utils';


@Component({
	selector: 'page-advancedSearch',
	templateUrl: './page-advancedSearch.component.html',
	styleUrls: ['./page-advancedSearch.component.css'],
	encapsulation: ViewEncapsulation.None

})
export class PageAdvancedSearchComponent implements OnInit {

	public structure
	public structure2

	public sortOptions: WOption[] = []
	public strategies: WOption[] = []

	constructor(public ms: MechanicsService,
		public qs: QueryParamsService) {

		this.ms.setEndpoint("advancedsearch")
		// this.qs.resetQP("advancedsearch");
	}

	ngOnInit(): void {
		const l = `pageAdvancedSearch ngOnInit() - `
		this.buildStuff()
	}


	buildStuff() {
		const l = `buildStuff() - `
		this.buildStructure()
		this.buildOptions()
	}

	buildStructure() {

		const l = `buildStructure() - `

		// console.log(`${l}this.qs.queryparams = `, deepClone(this.qs.queryParams))
		if(this.ms.bases64){
			this.qs.setQP('sort', 'image_similarity')
		}
		this.structure = this.qs.getQP("asStructure")

		// console.log(`${l}this.structure = `, deepClone(this.structure))

		// if coming back to this page from results page
		// remove facets
		this.qs.keepOnlySearchParams();

		const asStructure = this.qs.getQP("asStructure");
		// console.log(`${l}found asStructure in queryParams = `, asStructure)

		try {
			this.structure = JSON.parse(asStructure)
			// console.log(`${l}Parsed structure = `, this.structure)
		} catch (err) {
			// console.log(`${l}caught error (probably fine, no asStructure in queryParams) : `, err)
		}
	}

	buildOptions() {

		const l = `buildOptions() - `

		this.sortOptions = [
			"image_similarity",
			"score desc",
			"score asc",
			"applicationDate desc",
			"applicationDate asc",
			"st13 desc",
			"st13 asc",
		].map((value => ({
			value,
			label: this.ms.translate(("search_results_menu.sort_" + value))
		})))

		this.strategies = [
			{ value: "concept", label: "" },
			{ value: "shape", label: "" },
			{ value: "color", label: "" },
			{ value: "composite", label: "" },
		].map(obj => {
			obj.label = this.ms.translate(`page_similarlogo.${obj.value}`);
			return obj
		})
	}

	async search() {
		const l: string = `PageQueryBuilder search() - `;

		this.qs.setQP("asStructure", JSON.stringify(this.structure)) // Then, toPayload() will take the whole structure and pass it to ss.search(payload)
		this.qs.setQP("fg", "_void_")

		const route: string = this.ms.makeRoute({ subpath: 'results', caller: l })

		// Updates the URL accordingly without reloading the page.
		// Changing the query params in the URL will trigger a subscription in HOCSearchComponent ,
		// which in turn will automatically call SearchService.search() and refresh the results.
		await this.qs.queryParamsObjectToUrl(route)
	}

	get isSearchButtonActive(): boolean { // "canSearch" is already taken
		const l = `isSearchButtonActive - `
		return this.isBrickValid(this.structure)
	}

	isBrickValid(brick): boolean {
		const l = `isBrickValid - `
		
		const isValid: boolean = brick.bricks ?
		brick.bricks.every(b => this.isBrickValid(b)) : // every() will stop evaluating as soon as one of the bricks returns false
		canSearch(brick.value)
		
		// console.log(`${l}brick=`, brick)
		// console.log(`${l}canSearch("${brick.value}") = ${canSearch(brick.value)}`)
		// console.log(`${l}isValid? ${isValid}`)

		return isValid
	}
}
