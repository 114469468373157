import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import cacheBusting from '../../../../../assets-cache-busting.json';

import * as echarts from 'echarts';
import { WOption } from '@wipo/w-angular/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { PreferencesService } from 'src/app/_services/preferences.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { SearchService } from 'src/app/_services/search.service';
import { ExploreFacets, chartDef, SearchSolrResponse, Bucket } from 'src/app/interfaces';
import { deepClone, deduplicateStringArray } from 'src/app/utils';

@Component({
	selector: 'visu',
	templateUrl: './visu.component.html',
	styleUrls: ['./visu.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class VisuComponent implements OnInit {


	public displayMode = "graphs"; // "tables", "graphs"

	public charts: any = {} // filled in buildOneGraph(). Contains eCharts objects. { applicant : eChartObject, designation : eChartsObject } etc.
	private echartsInstance: any
	public solrFacets: ExploreFacets = null
	private worldmapJson!: JSON

	public tableSortColumn: string = "value"
	public tableSortOrder: number = 1

	private chartsConfig = {
		fontFamily: 'simplon, arial, meiryo, sans-serif',
		titleStyle: {
			fontWeight: 500,
			color: '#1a1a1a',
			fontSize: 18,
			fontFamily: 'simplon, arial, meiryo, sans-serif',
		},
		backgroundColor: 'rgba(222, 222, 222, 0.2)',
		barMaxWidth: 300,
		colors: {
			'single': '#53A5BE',
			'negate': '#A9A9A9',
			'selected': '#CBB177',
			'blues': ['#99E1F3', '#67D1F1', '#59C1E0', '#46ACC9', '#3195AF'].reverse(),
			'status': {
				'Registered': '#47B39C',
				'Ended': '#EC6B56',
				'Pending': '#4DC0E5',
				'Expired': '#A9A9A9',
				'Unknown': '#FCC154'
			}
		}
	}

	private getChartTitle(field: string): any {

		let title = {}

		title['left'] = 'center'
		title['text'] = this.ms.translate('page_visu.' + field).toUpperCase()
		title['textStyle'] = this.chartsConfig.titleStyle

		return title
	}

	private allChartsDefs: chartDef[] = [

		/*
			This is the list of all possible graphs.
			This list will be filtered, depending on what's available in each Solr response,
			then form the left column
		*/

		// tt:Toolip formatter
		{ type: 'pie', field: 'status', sort: 'val' },
		{ type: 'worldmap', field: 'designation', sort: 'val', tt: 'i18n' },
		{ type: 'worldmap', field: 'office', sort: 'val', tt: 'i18n' },
		{ type: 'worldmap', field: 'applicantCountryCode', sort: 'val', tt: 'i18n' },
		{ type: 'vbar', field: "type", sort: 'val', tt: 'basic' },
		{ type: 'hbar', field: "kind", sort: 'val', tt: 'basic' },
		{ type: 'hbar', field: 'markFeature', sort: 'val', tt: 'basic' },
		{ type: 'vbar', field: 'niceClass', sort: 'val', tt: 'nice' },
		//{ type: 'hbar', field: 'brandName', sort: 'count', tt: 'names' },
		{ type: 'hbar', field: 'applicant', sort: 'count', tt: 'names' },
		{ type: 'hbar', field: 'representative', sort: 'count', tt: 'names' },
		{ type: 'line', field: 'applicationDate', sort: 'val', tt: 'basic' },
		{ type: 'line', field: 'registrationDate', sort: 'val', tt: 'basic' },
		{ type: 'line', field: 'expiryDate', sort: 'val', tt: 'basic' },
		// { type: 'sunburst', field: 'Expired', sort: 'val', tt: 'basic' }, // "expiryDate" key does not exist.	Instead, it's several "Expired", "WillExpire", "WillExpireNext6Month" etc. I'm detecting all of this just by the existence of the "Expired" key (I assume the others are here too)


	//	{ type: 'hbar', field: 'qc', sort: 'code', tt: 'basic' } // Quality Control (?)
	];

	public chartsDefs: chartDef[] = []; // filtered list (depending on what's available in Solr's response) then used to build the left coumn

	public virtualCountries: WOption[] = [
		/*
		{
			label: "WIPO (Madrid, Lisbon, 6ter)",
			label2: "WO"
		},
		*/
		{
			label: "Lisbon",
			label2: "LISBON"

		},
		{
			label: "Madrid",
			label2: "WO"

		},
		{
			label: "6ter",
			label2: "SIXTER"

		},
		{
			label: "EUIPO", // European brands
			label2: "EM"
		},
		{
			label: "WHO (INN)",
			label2: "WHO"
		}
	]

	constructor(public ss: SearchService,
		public ms: MechanicsService,
		public ps: PreferencesService,
		public qs: QueryParamsService) {
	}


	onMenuAction($event): void {

		const l = `pageResults onMenuAction() - `

		// $event.name can be "export", "watch", "save"
		this[`_menuaction_${$event.name}`]($event.value)
	}
	
	onMenuChoice($event): void {

		const l = `pageResults onMenuChoice() - `
	}

	_menuaction_view_graphs(event): void {
		this.displayMode = "graphs";
	}

	_menuaction_view_tables(event): void {
		this.displayMode = "tables";
	}

	async ngOnInit(): Promise<void> {
		const l = `pageGraphs ngOnInit - `


		// console.log(`${l}`)
		this.worldmapJson = await lastValueFrom(
			this.ss.http.get(`assets/world.json?_=${cacheBusting['world']}`)
		) as JSON;

		echarts.registerMap('world', this.worldmapJson as any);
		// Nothing else? --> Yes : after ss.search, HOCsearch components calls this.setSearchResult(searchResult)
	}
	

	setSearchResult(solrResult: SearchSolrResponse) {
		const l = `graphsComponent setSearchResult() - `
		let cid: string = this.qs.getQP('_');
		this.ss.searchResult.init(solrResult, cid); // defines ss.facetsCache, ss.numFound, ss.lastUpdated, etc.
		this.solrFacets = solrResult.facets;
		this.buildGraphs()
		this.buildVirtualCountries()
	}

	setSearchError(err: HttpErrorResponse) {

		const l = `graphsComponent setSearchError() - `

		switch (err.status) {
			case 403:
				// stay in the search result page
				this.setSearchResult(this.ss.searchResult.getResult())
				this.ms.setSearchError(err)
			default:
				// back to the search form
				this.qs.queryParamsObjectToUrl(this.ms.makeRoute({ path: this.ms.endpoint, caller: l }))
				this.ms.setSearchError(err)
		}
	}

	buildVirtualCountries() {

		const buckets = this.solrFacets?.office?.buckets || []; // [ {val: 'AE', count: 5165}, {val: 'AL', count: 49} ]

		for (let vc of this.virtualCountries) {
			vc.value = buckets.find(bucket => bucket.val === vc.label2)?.count || 0
		}
	}

	onChartInit(ec) {

		this.echartsInstance = ec;
		// dataZoom is only active for line charts
		// so this will only be triggered for Date charts
		ec.on('dataZoom', function (evt) {
			let field = this.ps.getPref('chart', 'graphs')

			let model = ec.getModel()
			let data = model.option.xAxis[0].data;
			let zoom = model.option.dataZoom[0]

			let start = data[zoom.startValue];
			let end = data[zoom.endValue];
			this.ms.graphsRange.add(field, start, end)
			// this.cd.detectChanges() --> ???
		}.bind(this));

		this.echartsInstance.setOption({
			grid: { bottom: 0 }
		})
	}

	rebuildCurrentGraph(): void {
		let currChartName = this.ps.getPref('chart', 'graphs')
		let currChart: chartDef = this.chartsDefs.find(item => item.field === currChartName)
		this.buildOneGraph(currChart)
	}

	buildOneGraph(chart: chartDef): void { // chart = { type: 'hbar', field: 'markFeature', sort: 'val', tt: 'basic' }
		const l = `pageGraphs buildOneGraph() - `

		// get the buckets
		let buckets: Bucket[] = this.solrFacets[chart.field]?.buckets ? deepClone(this.solrFacets[chart.field].buckets) : []; // buckets = [{"val": "AF", "count": 135 }]

		if (chart.field.endsWith("Date")) {
			buckets = buckets.map(b => {
				b.val = (b.val as string).replace(/-01T.+Z/, "")
				return b;
			})
		} else if (chart.field === "qc") { // "qc" again? What the heck is that? --> Ah, Quality Control?
			buckets = buckets.map(b => {
				let val = (b.val as string)
				b['code'] = val.substring ? val.substring(val.length - 1) : val; // sort by the last digit of qc to join by severity
				// console.log(`${l}b=`, b)
				return b
			})
		}

		// sort the buckets
		let s: string = chart.sort // 'val'
		buckets = buckets.sort((a, b) => (a[s] > b[s]) ? 1 : ((b[s] > a[s]) ? -1 : 0))

		// get missing bucket
		// let missing = (this.solrFacets[chart.field] || {}).missing

		// if (missing?.count > 0) {
		// 	buckets[chart.type === 'vbar' ? 'push' : 'unshift']({
		// 		val: '_void_',
		// 		count: missing.count
		// 	})
		// }

		// initialize chart
		// console.log(`${l}Initializing chart '${chart.field}' as '${chart.type}'`);

		this.charts = this.charts || {};
		this.charts[chart.field] = this[`_${chart.type}Chart`](chart.field, buckets);

		try {
			this.charts[chart.field]['tooltip']['formatter'] = this[`_${chart.tt}Tooltip`].bind(this);
		}
		catch (err) {
			console.warn(`${l} No Tooltip formatter for '${chart.field}'`)
		}
	}

	buildGraphs(): void {
		const l: string = `pageGraphs buildGraphs() - `;

		// console.log(`${l}this.solrFacets = `, this.solrFacets)

		if (!this.solrFacets) {
			console.error(`${l}Caught error : No Solrfacets to transform! Aborting buildGraphs()`)
			return
		}

		let availableDataKeys: string[] = Object.keys(this.solrFacets);

		// keep QC only when exploring a dataset from an office --> Jer : what the heck is qc? --> Ah, Quality Control?
		if (!this.ms.officeCC) {
			availableDataKeys = availableDataKeys.filter(key => key !== 'qc');
		}

		this.chartsDefs = this.allChartsDefs.filter(chartDef => chartDef.field !== 'qc');

		// console.log(`${l}this.chartsDefs = `,this.chartsDefs)
		
		this.rebuildCurrentGraph()		
		//this.chartsDefs.forEach(chartDef => this.buildOneGraph(chartDef)) // passing chart = { type: 'hbar', field: 'markFeature', sort: 'val', tt: 'basic' }
	}

	// clicked on a chart name button in the left column
	changeGraph(chartName: string) {

		const l = `graphsComponent changeGraph()`
		this.ps.setPref("chart", chartName, 'graphs')
		this.qs.queryParamsObjectToUrl()
		this.doReset()
	}

	// -------------------
	// CHARTS INTERACTIONS
	// -------------------
	onChartClick($event): void {

		const l: string = `onChartClick() - `

		// console.log(`${l}$event=`, $event)

		let chartType = $event.seriesType; // "sunburst"
		let data = $event.data;

		switch (chartType) {
			case 'map':
				if (!data) { // clicking on an empty countru
					$event.returnValue = false
					return
				}
				break
			case 'treemap':
				if (!data || // clicking on breadcrumb
					data.children) { // clicking on top level of treemap
					return
				}
				break
			case 'line': return
		}

		let field = this.ps.getPref('chart', 'graphs')
		let value = data?.fvalue || $event.value // "US" for graph "map"


		// console.log(`${l}toggling field='${field}', value='${value}'`)

		// toggle select state
		this.ms.graphsSelection.toggle(field, value)

		// console.log(`${l}graphsSelection.selection=`, this.ms.graphsSelection.selection)

		switch (chartType) {
			case 'map':
				data.itemStyle['color'] = this.__color(field, value + "")
				this.echartsInstance.resize()
				break

			case "sunburst":
				// Preventing to rebuild the graph, it breaks the sunburst (prevents drilling down)
				break

			default:
				this.rebuildCurrentGraph()
				break
		}
	}

	onVirtualCountryClicked($event, obj: WOption) {

		const l = `graphsComponent onVirtualCountryClicked() - `

		// console.log(`${l}$event = `, $event, `obj=`, obj)
		/*
			{
				"label": "WHO (INN)",
				"label2": "WHO",
				"value": 6632
			}
		*/

		const isChecked: boolean = $event.target.checked

		if (isChecked) { // Don't use .toggle(). Those countries can be removed in different ways
			this.ms.graphsSelection.add(`office`, obj.label2)
		} else {
			// Unchecking a checkbox does the same thing as removing a facet/badge from queryParams2Human
			this.ms.graphsSelection.remove(`office`, obj.label2)
			this.qs.removeFacet(`office`, obj.label2)  // key="office", val="WHO"
		}
	}

	isVirtualCountryChecked(cc): boolean {

		return this.qs.queryParams.explore["fcoffice"]?.includes(cc)
	}

	doRangeFilter(): void {

		let field = this.ps.getPref('chart', 'graphs')

		if (!this.ms.graphsRange.contains(field)) return

		let value = this.ms.graphsRange.range[field]

		this.qs.setQP(`fc${field}`, `F${value[0]}T${value[1]}`)

		this.ms.graphsRange.reset(field)

		this.qs.queryParamsObjectToUrl()
	}

	doFilter(operator: string = ''): void {

		const l = `pageGraphs doFilter() - `

		let field = this.ps.getPref('chart', 'graphs'); // field="markFeature", that's the current graph we're on
		let toSet;

		/*
			graphsSelection.selection : {
				"markFeature": [
					"Figurative",
					"Combined",
					"Stylized characters"
				]
			}
		*/

		if (!this.ms.graphsSelection.contains(field)) { // custom object with custom methods by Mona
			// console.log(`${l}graphSelection does not contain "${field}", ignoring`)
			return
		} else {
			// console.log(`${l}graphSelection contains "${field}", proceeding`)
		}

		let currentValues: string[] = this.ms.graphsSelection.selection[field]; // ["Figurative","Combined","Stylized characters"]

		let prevValues = this.qs.getQP(`fc${field}`) || [];

		// console.log(`${l}prevValues=`, prevValues)		// []
		// console.log(`${l}currentValue=`,currentValues); // ["US"]

		toSet = prevValues.concat(currentValues.map(v => operator + v));
		toSet = deduplicateStringArray(toSet); // What about deduplicating, Mona??

		// console.log(`${l}Setting QueryParam : 'fc${field}'=`,toSet)

		this.qs.setQP(`fc${field}`, toSet)

		this.ms.graphsSelection.reset(field)

		this.qs.queryParamsObjectToUrl()
	}

	doReset(): void {
		let field: string = this.ps.getPref('chart', 'graphs') // "niceClass"
		this.ms.graphsSelection.reset(field)
		this.ms.graphsRange.reset(field)
		this.rebuildCurrentGraph()
	}

	browse(): void {
		const currentChartName: string = this.ps.getPref('chart', 'graphs'); // Getting preferences.graphs.chart == "applicant"

		if (this.ms.graphsSelection.contains(currentChartName)) {
			return this.doBrowse()
		}
		if (this.ms.graphsRange.contains(currentChartName)) {
			return this.doRangeBrowse()
		}
	}

	doRangeBrowse(): void {

		const l = `pageGraphs doRangeBrowse() - `

		let field: string = this.ps.getPref('chart', 'graphs') // "kind"

		if (!this.ms.graphsRange.contains(field)) return

		let value = this.ms.graphsRange.range[field]

		this.qs.setQP('rows', this.ps.getPref('rows', 'explore'))
		this.qs.setQP(`fc${field}`, `F${value[0]}T${value[1]}`)
		this.qs.setQP('_', Date.now()) // flush the cache to force re-execution of same query but with different params

		const route = this.ms.makeRoute({ path: 'explore', subpath: 'results', caller: l })
		this.qs.queryParamsObjectToUrl(route)
	}

	doBrowse(): void {

		const l = `pageGraphs doBrowse() - `

		let field: string = this.ps.getPref('chart', 'graphs') // "kind"

		if (!this.ms.graphsSelection.contains(field)) return

		let value = this.ms.graphsSelection.selection[field]

		let prevValues = this.qs.getQP(`fc${field}`) || []

		this.qs.setQP('rows', this.ps.getPref('rows', 'explore'))
		this.qs.setQP(`fc${field}`, prevValues.concat(value.map(v => { return v })))
		this.qs.setQP('_', Date.now()) // flush the cache to force re-execution of same query but with different params

		const route = this.ms.makeRoute({ path: 'explore', subpath: 'results', caller: l })
		this.qs.queryParamsObjectToUrl(route)
	}


	get selectionList(): string {

		const l = `pageGraphs selectionList() - `

		/*

		We can have :

		graphsRange : {
			"range": {
				"applicationDate": [
					"2016-07",
					"2020-01"
				]
			}
		}

		or :

		graphsSelection : {
			"selection": {
				"markFeature": [
					"Undefined",
					"Three dimensional"
				]
			}
		}

		*/

		let toReturn = "";
		const currentChartName: string = this.ps.getPref('chart', 'graphs'); // Getting preferences.graphs.chart == "applicant"

		const selection = this.ms.graphsSelection.selection[currentChartName] || this.ms.graphsRange.range[currentChartName]

		if (!selection) {
			// console.log(`${l}selectionList = `, toReturn)
			return null
		}

		toReturn = selection.map(s => this.__i18n(currentChartName, s)).join(", ")

		// console.log(`${l}selectionList = `, toReturn)

		return toReturn
	}

	// --------------
	// CHARTS HELPERS
	// --------------

	// -- tooltips

	// tooltips are triggered on axis
	// axis can contain several series but not in our case
	// => pick up the first
	_namesTooltip(params) {

		params = Array.isArray(params) ? params[0] : params;

		return `<b>${this.ms.translate('page_visu-tooltip.' + params.seriesName)}</b>
				(${this.ms.numberFormatter.format(params.data.value)} ${this.ms.translate('general_words.records')})<br />
				<div style="background-color:${this.chartsConfig.backgroundColor};
							margin-top:10px;padding:5px;
							max-width:350px;white-space:normal">
				${params.data.name}
				</div>`
	}
	// get nice class description from translation file
	_niceTooltip(params) {

		const l = `pageGraphs _niceTooltip() - `
		let toReturn: string

		try {
			params = Array.isArray(params) ? params[0] : params;

			let desc = this.ms.translate('niceClass.' + params.data.fvalue)
				.toLowerCase().split(';')

			toReturn = `<b>${this.ms.translate('page_visu-tooltip.niceClass')} ${params.data.fvalue}</b>
			(${this.ms.numberFormatter.format(params.data.value)} ${this.ms.translate('general_words.records')})<br />
			<div style="background-color:${this.chartsConfig.backgroundColor};
						margin-top:10px;padding:5px;
						max-width:350px;white-space:normal">
			<small><i><p>- ${desc.join('<p>- ')}</i></small></div>`

		} catch (err) {
			// console.log(`${l}Caught error :`, err)
			return null
		}

		return toReturn
	}

	_basicTooltip(params): string {

		const l = `pageGraphs _basicTooltip() - `
		let toReturn: string

		params = Array.isArray(params) ? params[0] : params;

		try {
			toReturn = `<b>${params.data.name}</b>
				(${this.ms.numberFormatter.format(params.data.value)} ${this.ms.translate('general_words.records')})`
		} catch (err) {
			// console.log(`${l}Caught error : cannot read params.data.name - params = `, params)
			return null
		}

		return toReturn
	}
	_i18nTooltip(params): string {

		const l = `pageGraphs _basicTooltip() - `
		let toReturn: string

		let field = params.seriesName

		params = Array.isArray(params) ? params[0] : params;

		try {
			toReturn = `<b>${this.__i18n(field, "" + params.data.name)}</b>
				(${this.ms.numberFormatter.format(params.data.value)} ${this.ms.translate('general_words.records')})`
		} catch (err) {
			// console.log(`${l}Caught error : cannot read params.data.name - params = `, params)
			return null
		}

		return toReturn
	}

	_undefinedTooltip(params) { }

	_calculateWidth(values: string[]): number {
		let countValues = values.map(v => v.length)
		return Math.min(Math.max(...countValues) * 6 + 20, 320)
	}


	// -------------------
	// CHARTS INIT BY TYPE
	// -------------------

	_lineChart(field: string, buckets: Bucket[]): any {
		const l = `_lineChart`

		return {
			title: this.getChartTitle(field),
			textStyle: { fontFamily: this.chartsConfig.fontFamily },
			grid: { left: '3%', right: '4%', containLabel: true },
			tooltip: { trigger: 'axis', textStyle: { fontFamily: this.chartsConfig.fontFamily } },
			xAxis: {
				type: 'category',
				data: buckets.filter(v => v.count > 0).map(b => b.val + ''),
				axisPointer: { type: 'shadow' },
				axisLabel: { fontWeight: 'normal', hideOverlap: false },
				show: true
			},
			yAxis: { type: 'value', axisLabel: { showMinLabel: false }, show: true },
			toolbox: {
				feature: { restore: {} }
			},

			series: [{
				type: 'line',
				name: field,
				smooth: true,
				showSymbol: false,
				showBackground: true,
				backgroundStyle: { color: this.chartsConfig.backgroundColor },
				data: buckets.filter(v => v.count > 0).map(b => ({
					name: this.ms.translate(field + "." + b.val),
					value: b.count,
					fvalue: b.val
				})),
				lineStyle: { width: 2, color: this.chartsConfig.colors['single'] }
			}],

			dataZoom: [
				{
					type: 'inside', xAxisIndex: 0, minSpan: 1,
					zoomOnMouseWheel: false, moveOnMouseMove: false
				},
				{
					type: 'slider', xAxisIndex: 0, minSpan: 1,
					textStyle: { color: '#8392A5' },
					// handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
					// handleSize: '75%',
					moveHandleSize: 0,
					selectedDataBackground: {
						areaStyle: { opacity: 1, color: this.chartsConfig.colors['single'] },
						lineStyle: { opacity: 0.95, color: this.chartsConfig.colors['single'] }
					},
					dataBackground: {
						areaStyle: { opacity: 0.3, color: this.chartsConfig.colors['single'] },
						lineStyle: { opacity: 0.4, color: this.chartsConfig.colors['single'] }
					},
					brushSelect: true
				}
			]
		}

	}

	_pieChart(field: string, buckets: Bucket[]): any {
		return {
			toolbox: {
				show: false,
				feature: { saveAsImage: {} }
			},
			title: this.getChartTitle(field),
			textStyle: {
				fontFamily: this.chartsConfig.fontFamily
			},
			grid: {
				left: '3%',
				right: '3%',
				containLabel: true
			},
			legend: {
				top: '5%',
				right: '5%',
				orient: 'vertical',
				selectedMode: false,
				backgroundColor: this.chartsConfig['backgroundColor'],
				data: buckets.map(b => ({
					name: this.__i18n(field, "" + b.val) + ` (${this.ms.numberFormatter.format(b.count)})`,
				}))
			},
			tooltip: {
				textStyle: {
					fontFamily: this.chartsConfig.fontFamily
				}
			},
			series: [{
				type: 'pie',
				name: field,
				radius: ['40%', '70%'],

				data: buckets.map(b => ({
					name: this.__i18n(field, "" + b.val) + ` (${this.ms.numberFormatter.format(b.count)})`,
					value: b.count,
					fvalue: b.val,

					itemStyle: {
						borderColor: '#fff',
						borderWidth: 2,
						borderRadius: 5,
						color: this.__color(field, "" + b.val)
					},
					label: { show: false, },
					emphasis: { label: { show: true } }
				}))
			}]
		}
	}

	_vbarChart(field: string, buckets: Bucket[]): any {

		const l = `_vbarChart`

		return {
			title: this.getChartTitle(field),
			textStyle: {
				fontFamily: this.chartsConfig.fontFamily
			},
			grid: {
				left: '3%',
				right: '4%',
				containLabel: true
			},
			tooltip: {
				trigger: 'axis',
				textStyle: {
					fontFamily: this.chartsConfig.fontFamily
				},
				// Keeping this for reference in case we want to change the
				// default position of tooltip
				// position: ['0%', '0%'],
				// position: function(point, params, dom, rect, size) {
				//	//Where point is the current mouse position, and there are two attributes in size: viewSize and contentSize, which are the size of the outer div and tooltip prompt box respectively
				//	const x = point[0];//
				//	const y = point[1];
				//	const viewWidth = size.viewSize[0];
				//	const viewHeight = size.viewSize[1];
				//	const boxWidth = size.contentSize[0];
				//	const boxHeight = size.contentSize[1];
				//	const posX = 0;//x coordinate position
				//	const posY = 0;//y coordinate position

				//	if(x<boxWidth){//The left side cannot be released
				//		posX = 5;
				//	}else{//Left down
				//		posX = x-boxWidth;
				//	}

				//	if(y<boxHeight){//Can't let go of the top
				//		posY = 5;
				//	}else{//The upper side can be put down
				//		posY = y-boxHeight;
				//	}

				//	return [posX, posY];
				//},
			},
			xAxis: {
				type: 'category',
				data: buckets.map(b => b.val + ""),
				axisPointer: { type: 'shadow' },
				axisLabel: {
					fontWeight: 'normal',
					formatter: function (val) {
						return this.__i18n(field, "" + val)
					}.bind(this),
					hideOverlap: false
				},
				triggerEvent: true,
				show: true
			},
			yAxis: {
				type: 'value',
				axisLabel: { showMinLabel: false },
				show: true
			},

			series: [{
				type: 'bar',
				name: field,
				showBackground: true,
				barMaxWidth: this.chartsConfig.barMaxWidth,
				backgroundStyle: {
					color: this.chartsConfig.backgroundColor
				},
				data: buckets.map(b => ({
					name: this.__i18n(field, "" + b.val),
					value: b.count,
					fvalue: b.val,
					label: {
						show: false,
						position: "top",
						align: "center",
						distance: 10,
					},
					emphasis: { label: { show: true } },
					itemStyle: { color: this.__color(field, "" + b.val) }
				}))
			}]
		}
	}

	_hbarChart(field: string, buckets: Bucket[]): any {

		let yAxisLabels: string[] = buckets.map(b => this.__i18n(field, "" + b.val));

		let leftSpace = this._calculateWidth(yAxisLabels)

		return {
			title: this.getChartTitle(field),
			textStyle: { fontFamily: this.chartsConfig.fontFamily },
			grid: {
				left: leftSpace + 20 + 'px',
				right: '4%',
				containLabel: false
			},
			tooltip: {
				trigger: 'axis',
				textStyle: { fontFamily: this.chartsConfig.fontFamily }
			},
			yAxis: {
				type: 'category',

				data: buckets.map(b => b.val + ""),

				axisPointer: { type: 'shadow' },
				axisLabel: {
					width: leftSpace,
					formatter: function (val) {
						return this.__i18n(field, "" + val)
					}.bind(this),
					align: 'right',
					overflow: 'truncate'
				},
				triggerEvent: true,
				show: true
			},
			xAxis: {
				type: 'value',
				axisLabel: { showMinLabel: false },
				show: true
			},
			series: [{
				type: 'bar',
				name: field,
				barMaxWidth: this.chartsConfig.barMaxWidth,
				showBackground: true,
				backgroundStyle: {
					color: this.chartsConfig.backgroundColor
				},
				data: buckets.map(b => ({
					name: this.__i18n(field, "" + b.val),
					value: b.count,
					fvalue: b.val,
					label: {
						show: false,
						position: "right",
						verticalAlign: "middle",
						distance: 10,
						align: "left",
					},
					emphasis: { label: { show: true } },
					itemStyle: { color: this.__color(field, "" + b.val) }
				}))
			}]
		}
	}

	__color(field: string, val: string) {
		let selected: boolean = this.ms.graphsSelection.state(field, val)

		// common selection color for all
		if (selected) return this.chartsConfig.colors['selected']

		switch (field) {
			case 'qc':
				if (val.endsWith('2')) return '#AF0606' // missing info (dark)
				if (val.endsWith('3')) return '#F16868' // wrong value (medium)
				return '#FFCCCC' // date format (light)
			case 'status':
				return this.chartsConfig.colors['status'][val]

			// map types have default color already set in the chart def
			case 'applicantCountryCode':
			case 'designation':
			case 'office':
				return

			default:
				if (val === '_void_') return this.chartsConfig.colors['negate']
				return this.chartsConfig.colors['single']
		}
	}

	__i18n(field: string, val: string) {
		if (val === "_void_") return "-"

		switch (field) {
			case 'office':
				return this.ms.translate(field + "." + val) + " (" + this.ms.translate('designation.' + val) + ")"
			case 'applicantCountryCode':
				return this.ms.translate('designation.' + val)

			// no translation for these
			case 'niceClass':
			case 'brandName':
			case 'applicant':
			case 'representative':
			case 'applicationDate':
			case 'registrationDate':
			case 'expirationDate':
				return val

			default:
				return this.ms.translate(field + "." + val)
		}
	}

	_worldmapChart(field: string, buckets: Bucket[]): any {
		if(buckets.length === 0){
			return
		}
		let _chart = {
			title: this.getChartTitle(field),
			textStyle: { fontFamily: this.chartsConfig.fontFamily },
			tooltip: {
				trigger: 'item',
				textStyle: { fontFamily: this.chartsConfig.fontFamily }
			},
			toolbox: {
				feature: {
					// dataZoom: { },
					restore: {}
					// saveAsImage: {}
				}
			},
			series: [{
				name: field,
				type: 'map',
				map: 'world',
				roam: true,
				// scaleLimit: { min: 1, max: 5 },
				selectedMode: false,
				itemStyle: { borderWidth: 0 },
				label: { show: false },
				emphasis: { label: { show: false }, itemStyle: { areaColor: this.chartsConfig.colors['selected'] } },
				data: buckets.map(b => ({
					value: b.count,
					// name: this.__i18n(field, "" + b.val),
					name: b.val,
					fvalue: b.val,
					itemStyle: { color: this.__color(field, "" + b.val) }
				}))
			},
			]
		};

		let _max = Math.max.apply(Math, buckets.map(function (b) { return b.count }))
		let _min = Math.min.apply(Math, buckets.map(function (b) { return b.count }))

		_chart['visualMap'] = {
			min: _min < _max ? _min : 0,
			max: _max,
			color: _min < _max ? this.chartsConfig.colors['blues'] : [this.chartsConfig.colors['single']],
			left: 'left',
			top: 'bottom',
			calculable: _min < _max
		}

		return _chart
	}

	_sunburstChart(field: string, buckets: Bucket[]): any {

		if (field === "Expired") { //	There is no "expirationDate" key. Instead, it's several "Expired", "WillExpire", "WillExpireNext6Month" etc.
			return {
				title: this.getChartTitle(field),
				textStyle: { fontFamily: this.chartsConfig.fontFamily },
				zoom: 3.23, //Perspective zoom ratio
				grid: {
					left: '3%',
					right: '3%',
					containLabel: true
				},
				legend: {
					top: '5%',
					right: '5%',
					orient: 'vertical',
					selectedMode: false,
					backgroundColor: this.chartsConfig['backgroundColor'],
					data: buckets.map(b => ({
						name: `${this.ms.translate(field + "." + b.val)} (${b.count})`
					}))
				},
				tooltip: {
					textStyle: {
						fontFamily: this.chartsConfig.fontFamily
					}
				},

				series: [ // https://echarts.apache.org/en/option.html#series
					{
						// name: 'Expiration', // Required for legend to appear
						type: 'sunburst',
						legend: {},// Required for legend to appear
						color: this.chartsConfig.colors['blues'],
						data: [
							{
								name: `Expired`,
								value: this.solrFacets.Expired.count, // value of parent node can be left unset, and sum of
								// children values will be used in this case.
								// If is set, and is larger than sum of children nodes,
								// the reset can be used for other parts in parent.
								fvalue: "Expired",

								children: [
									{
										value: this.solrFacets.ExpiredLastMonth.count,
										name: `last month`,
										fvalue: "ExpiredLastMonth",
									},
									{
										value: this.solrFacets.Expired.count - this.solrFacets.ExpiredLastMonth.count,
										name: `before last month`,
										fvalue: "ExpiredBeforeLastMonth",
									}
								],
								label: {
									// label of parent1, which will not be inherited for children
								}
							},
							{
								name: `Will expire`,
								value: this.solrFacets.WillExpire.count,
								fvalue: "WillExpire",
								children: [
									{
										value: this.solrFacets.WillExpireNextYear.count,
										name: `within a year`,
										fvalue: "WillExpireNextYear",
										children: [
											{
												value: this.solrFacets.WillExpireNext6Month.count,
												name: `within 6 months`,
												fvalue: "WillExpireNext6Month",
												children: [
													{
														value: this.solrFacets.WillExpireNextMonth.count,
														name: `within 1 month`,
														fvalue: "WillExpireNextMonth",
													},
												]
											},
										]
									},
									{
										value: this.solrFacets.WillExpire.count - this.solrFacets.WillExpireNextYear.count,
										name: `in more than a year`,
										fvalue: "WillExpireInOverAYear",
									}
								],
							}
						],

						emphasis: {
							focus: 'ancestor'
						},

						levels: [
							{},

							{
								r0: '5%',
								r: '35%',
								itemStyle: { borderWidth: 2 },
								label: { rotate: 'tangential' },
								emphasis: { itemStyle: { color: this.chartsConfig.colors['selected'] } },
							},
							// { // Will expire
							//	 label: sunburstDefaultLabelStyling
							// },
							// { // Will expire within a year
							//	 label: sunburstDefaultLabelStyling
							// },
							// { // Will expire within 6 months
							//	 label: sunburstDefaultLabelStyling
							// },

							{
								r0: '35%',
								r: '50%',
								itemStyle: { borderWidth: 2 },
								label: { show: false },
								emphasis: { label: { show: false }, itemStyle: { color: this.chartsConfig.colors['selected'] } },
							},
							{
								r0: '50%',
								r: '65%',
								itemStyle: { borderWidth: 2 },
								label: { show: false },
								emphasis: { label: { show: false }, itemStyle: { color: this.chartsConfig.colors['selected'] } },
							},
							{ // Will expire within 1 months
								r0: '65%',
								r: '80%',
								itemStyle: { borderWidth: 2 },
								label: { show: false },
								emphasis: { label: { show: false }, itemStyle: { color: this.chartsConfig.colors['selected'] } },
							}
						]
					}
				],
			}
		}
	}


	// ------
	// TABLES
	// ------

	sortTableBy(what: string): void {
		const l = `sortTableBy() - `
		if (this.tableSortColumn === what) {
			this.tableSortOrder *= -1
		} else {
			this.tableSortColumn = what
		}

	}

	toggleRow(obj: any): void {
		const l = `toggleRow - `
		const field = this.ps.getPref('chart', 'graphs')
		this.ms.graphsSelection.toggle(field, obj.fvalue)
	}

	toggleAllRows($event): void {
		const l = `toggleAllRows - `
		const field = this.ps.getPref('chart', 'graphs')

		if (this.ms.graphsSelection.count(field)) {
			this.ms.graphsSelection.reset()
		} else {
			const field = this.ps.getPref('chart', 'graphs')
			this.charts[this.ps.getPref('chart', 'graphs')]?.series[0]?.data.forEach(obj => this.ms.graphsSelection.add(field, obj.fvalue))
		}
	}

	isOneSelected(obj: any): boolean {
		const l = `isOneSelected`
		const field = this.ps.getPref('chart', 'graphs')
		return this.ms.graphsSelection.state(field, obj.fvalue)
	}

	get isAnySelected() {
		const field = this.ps.getPref('chart', 'graphs')
		return this.ms.graphsSelection.count(field)
	}

	get isAllSelected(): boolean {
		const field = this.ps.getPref('chart', 'graphs')
		return this.charts[this.ps.getPref('chart', 'graphs')]?.series[0]?.data?.length === this.ms.graphsSelection.count(field)
	}
}
