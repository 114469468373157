import { Injectable } from '@angular/core';
import { deepClone } from '../utils';
import { MechanicsService } from './mechanics.service';

// the defaults if none in the localstorage
const prefsDefaults: any = {

	quicksearch: {
		layout: 'grid',
		rows: '30',
		sort: 'score desc',
		filters: 'o', // o - opened, c - collapsed
		niceClassOrder : "label2" // corresponds to relevance
	},
	similarlogo: {
		layout: 'gallery',
		rows: '60',
		sort: 'score desc',
		filters: 'o',
		niceClassOrder : "label2" // corresponds to relevance
	},
	similarname: {
		layout: 'grid',
		rows: '30',
		sort: 'score desc',
		filters: 'o',
		niceClassOrder : "label2" // corresponds to relevance
	},
	goodsservices: {
		layout: 'grid',
		rows: '30',
		sort: 'score desc',
		filters: 'o',
		niceClassOrder : "label2" // corresponds to relevance
	},
	advancedsearch : {
		layout: 'grid',
		rows: '30',
		sort: 'score desc',
		filters: 'o', // o - opened, c - collapsed
	},
	graphs: {
		rows: '0',
		chart: 'designation'
	},
	explore: {
		rows: '30',
		layout: 'grid',
		sort: 'applicationDate desc',
		filters: 'c'
	},
	brand: {

	},
	reports: {
		layout: 'list'
	},
	all : {
		dateFormat : "yy-mm-dd"
	}
}


@Injectable({
	providedIn: 'root'
})
export class PreferencesService {

	public preferences: any;

	constructor(private ms: MechanicsService) {
		this.loadPreferences()
	}

	async storePreferences() {

		const l = `storePreferences() - `

		// serialize and store preferences for the page in local storage

		// Little hack to give Angular some time for ngModel to reflect in preferences
		await new Promise(resolve => setTimeout(resolve))

		// console.log(`${l}storing preferences = `, deepClone(this.preferences))

		localStorage.setItem(
			`gbd.preferences`,
			JSON.stringify(this.preferences)
		);
	}

	loadPreferences(): void {

		const l = `loadPreferences() - `

		// deserialize the preferences from local storage

		const localPrefsStr: string = localStorage.getItem(`gbd.preferences`);
		let localPrefs

		if (localPrefsStr) {
			try {
				localPrefs = JSON.parse(localPrefsStr)
			} catch (err) {
				console.error("Found local preferences in LocalStorage, but could not parse them!")
			}
		}

		this.preferences = localPrefs || prefsDefaults;

		// Thorough prefs init and checks
		for (let key of Object.keys(prefsDefaults)) {
			this.preferences[key] =  Object.assign({}, prefsDefaults[key], (this.preferences[key] || {}));
		}

		this.storePreferences() // Just in case it was modified/updated by the thorough check above, so we're up to date in the LS
	}

	get currentPrefs(){

		return this.preferences[this.ms.endpoint]

	}

	getPref(which: string, endpoint: string = this.ms.endpoint): string { // Utility to easily get any preference, for instance from a template. ps.getPref('layout') == 'grid'

		const l: string = `PS getPref() - `

		// console.log(`${l}this.ms.endpoint = '${this.ms.endpoint}' - typeof this.ms.endpoint = '${typeof this.ms.endpoint}'`);

		if (!endpoint) {
			// console.log(`${l}'this.ms.endpoint' is undefined (yet), the page is probably still loading. Skipping for now`);
			return null
		}

		if (!this.preferences[endpoint]) {
			// console.log(`${l}this.preferences[${endpoint}] is undefined, attempting to load some default value`);
			this.preferences[endpoint] = prefsDefaults[endpoint] || {}
			// console.log(`${l}this.preferences[${endpoint}] is now = `, this.preferences[endpoint]);
			this.storePreferences()
		}

		try {
			return this.preferences[endpoint][which]
		} catch (err) {
			console.error(`${l}Could not return preferences[${endpoint}][${which}] : endpoint='${endpoint}' - which='${which}''`);
			return ""
		}
	}

	setPref(which: string, value: any, endpoint: string = this.ms.endpoint) {

		const l: string = `PS setPref() - `
		if (!endpoint) {
			// console.log(`${l}'this.ms.endpoint' is undefined (yet), the page is probably still loading. Skipping for now`);
			return null
		}
		this.preferences[endpoint] = this.preferences[endpoint] || {};
		this.preferences[endpoint][which] = value
		this.storePreferences()
	}
}
