import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { countriesByContinent } from 'src/app/_imports/regions';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { SearchService } from 'src/app/_services/search.service';
import { decrypt, deepClone } from 'src/app/utils';


@Component({
	selector: 'page-coverage',
	templateUrl: './page-coverage.component.html',
	styleUrls: ['./page-coverage.component.css']
})
export class PageCoverageComponent implements OnInit {

	public continentsCCs = Object.keys(countriesByContinent);

	public continents = [ // Jer: "data"?? Who names their variable "data"??
		/*
			{
			continentCC : "EUR",
			countries : [
				{
					cc : "FR",
					records: '5001',
					earliest: '1973-12-04',
					updated: '2021-10-15',
					latest: '2021-09-21'
				}
			]
			}
		*/
	]

	constructor(private ss: SearchService,
		private qs: QueryParamsService,
		public ms: MechanicsService,
		private router: Router) {

		const l = `page-coverage constructor - `

		this.ms.setEndpoint("coverage")
		// this.qs.resetQP("coverage");
	}

	async ngOnInit(): Promise<void> {

		const l = `PageCoverageComponent ngOnInit() - `


		if (!this.ms.coverageDataCache) {
			try {
				// console.log(`${l}Getting coverage...`)
				this.ms.setLoading(true)
				this.ms.coverageDataCache = await this.ss.getCoverage();
				this.ms.coverageDataCache = decrypt(this.ms.coverageDataCache); // decrypt() returns the data as is if can't decrypt
				// console.log(`${l}got coverage = `, deepClone(this.ms.coverageDataCache)) // OK with YYYY-MM-DD
			} catch (err) {
				// console.log(`${l}caught error = `, err)
				this.ms.setSearchError(err)
			}

			this.ms.setLoading(false)
		}

		this.transformContinents();
	}

	doSearch(officeCode): void {
		const l = `pageCoverage doSearch() - `
		this.ms.officeCC = officeCode


		// back to vanilla QP
		this.qs.resetQP('*', l)
		this.router.navigate([this.ms.makeRoute({ path: 'quicksearch', caller: l })])
	}
	doReset(): void {
		const l = `pageCoverage doReset() - `
		this.ms.officeCC = null

		// back to vanilla QP
		this.qs.resetQP('*', l)
		this.router.navigate([this.ms.makeRoute({ path: 'quicksearch', caller: l })])
	}

	doExplore(officeCode): void {

		const l = `pageCoverage doExplore() - `
		// set query params for explore endpoint
		this.qs.resetQP('explore', l)
		this.qs.setQP('rows', '0', 'explore') // graphs query should not return rows
		// this.qs.setQP('office', officeCode, 'explore')
		this.qs.setQP('_', Date.now(), 'explore') // flush the cache to force re-execution of same query but with different params

		// setEndpoint manually here so that the queryParams2Url function would work
		// a hacky hack!
		this.ms.setEndpoint('explore')

		this.ms.graphsSelection.reset() // reset graph selection
		this.ms.graphsRange.reset() // reset graph range

		this.ms.officeCC = officeCode
		const route = this.ms.makeRoute({ path: 'explore', subpath: 'graphs', caller: l })

		this.qs.queryParamsObjectToUrl(route)
	}

	transformContinents() {

		const l = `pageCoverage transformData() - `

		let coverageData = deepClone(this.ms.coverageDataCache); // If I don't deepClone it, dates will be transformed to human, and this will affect (and corrupt) coverageDataCache by reference

		if (!coverageData.coverage) return;

		coverageData = coverageData.coverage;

		/*
			LOG coverageData =  {
				"DE": {
					"records": "2351625",
					"earliest": "1875-05-01",
					"updated": "2022-11-09",
					"latest": "2022-11-09"
				},
				"DK": {
					"records": "300324",
					"earliest": "1880-11-18",
					"updated": "2022-09-23",
					"latest": "2022-09-23"
				},
			}

		*/

		this.continents = this.continentsCCs.map(continentCC => ({
			continentCC,
			countries: []
		}));

		Object.keys(coverageData).forEach((cc: string) => {

			let obj = coverageData[cc];

			obj.cc = cc;

			/*
				LOG obj={
					"records": "21138",
					"cc": "AL",
					"earliest": "1961-10-07",
					"updated": "2022-06-02",
					"latest": "2022-05-24",
				}
			*/

			// console.log(`${l}obj=`, obj)

			if (this.ms.officeCC && this.ms.officeCC !== obj.cc) {
				// console.log(`${l}Filtering out '${obj.cc}' as it's not '${this.ms.officeCC}'`)
				return
			}
			if (obj['updated'] != 'n/a' && obj['latest'] != 'n/a' && obj['updated'] < obj['latest']){
				obj['updated'] = obj['latest']
			}
			if (obj['updated'] == 'n/a' && obj['latest'] != 'n/a'){
				obj['updated'] = obj['latest']
			}

			for (let key of ["earliest", "latest", "updated"]) {
				if (obj[key] === 'n/a') delete obj[key]
				if (!obj[key]) continue

				// console.log(`${l}obj[key]='${obj[key]}'`)
				const date: Date = new Date(obj[key]);
				// console.log(`${l}Formating date = `, date)
				obj[key] = this.ms.dateFormatterHuman.format(date); // "February 1st, 2022"
			}
			
			for (let continentCC of this.continentsCCs) {
				if (countriesByContinent[continentCC].includes(obj.cc)) {
					this.continents.find(o => o.continentCC === continentCC).countries.push(obj)
					break;
				}
			}
		});

		// console.log(`${l}raw continents = `, deepClone(this.continents))

		this.continents = this.continents
			.filter(obj => obj.countries.length)
			.map(obj => {
				obj.countries.sort((a, b) => a.office > b.office ? 1 : -1);
				return obj;
			});

		// console.log(`${l}final continents = `, deepClone(this.continents))

	}
}
