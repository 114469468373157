import { Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { QueryParamsClassified } from 'src/app/interfaces';


import { SearchService } from 'src/app/_services/search.service';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { PreferencesService } from 'src/app/_services/preferences.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { RecentSearchesService } from 'src/app/_services/recentSearches.service';


const _tm_mapping = {
	'fcstatus':{
		'name': 'tmStatus',
		'replace': {
			'Pending':'Filed'
		}
	},
	'fcapplicationDate':{
		'name': 'ad',
		'transform': 'tm_date_tranfsormer'
	},
	'fcregistrationDate':{
		'name': 'rd',
		'transform': 'tm_date_tranfsormer'
	},
	'fcmarkFeature':{
		'name':'tmType',
		'replace':{
			"Three dimensional":"3-D"
		}
	},
	'fcoffice':{
		'name': 'offices'
	},
	'applicant':{
		'name': 'appName'
	},
	'usDesignClass': {
		'name': 'viennaCode',
		'replace': {
			',': ',OR,',
			'AND': ',AND,',
			'NOT': ',NOT,',
			' ': ''
		},
		'extra':{
			'viennaCodeVersion':'uspto'
		}
	},
	'viennaClass': {
		'name': 'viennaCode',
		'replace': {
			',': ',OR,',
			'AND': ',AND,',
			'NOT': ',NOT,',
			' ': ''
		},
		'extra':{
			'viennaCodeVersion':'wipo'
		}
	},
	'niceClass':{
		'name': 'niceClass',
		'replace': {
			',': ',OR,',
			'NOT': ',NOT,',
			'AND': ',AND,',
			' ': ''
		}
	},
	'brandName': {
		'name': 'basicSearch'
	},
	'strategy':{
		'name': 'criteria',
		'values':{
			'exact': 'W',
			'fuzzy': 'F',
			'embedded': 'C',
			'phonetic': 'I',
			'default': 'W'
		}
	},
	'by': {
		'mappings': {
			'applicant':'appName',
			'brandName': 'basicSearch',
			'number': ['applicationNumber', 'registrationNumber']
		},
		'values': 'v'
	}
}

const tm_view_base_url = "https://www.tmdn.org/tmview/#/tmview/results?page=1&pageSize=30&"


@Component({
	selector: 'results-info',
	templateUrl: './comp-results-info.component.html',
	styleUrls: ['./comp-results-info.component.css'],
	encapsulation: ViewEncapsulation.None,
})

export class CompResultsInfo implements OnChanges {

	// searchResult from solr response
	@Input() searchResultUpdateTime: number = 0;

	@Input() numFound: number = 0;
	@Input() end: number = 0;
	@Input() start: number = 0;

	@Input() interactive: boolean = true;

	public isExploringGraphs: boolean = false
	public isExploringRecords: boolean = false

	public isFromQuery:boolean = this.ms.endpoint === 'explore' && this.ms.prev_endpoint != null
	public shouldSaveDisplay: string = 'none'
	public newSearchName: string = ''

	public params: QueryParamsClassified = {
		'query': {},
		'facets': {}
	};

	public isTMViewVisible = false


	constructor(public ss: SearchService,
		private lc: Location,
		public ps: PreferencesService,
		public ms: MechanicsService,
		public rss: RecentSearchesService,
		public qs: QueryParamsService) {
			this.isTMViewVisible = window.location.href.includes('results');


	}

	ngOnChanges(changes: SimpleChanges) {

		const l = `SearchResultsInfo ngOnChanges() -`
		// when the searchResult is updated
		if (changes.searchResultUpdateTime && !changes.searchResultUpdateTime.firstChange) {
			this.params = this.qs.queryParams2Object()
		}
		let asStructure = JSON.parse(this.qs.getQP('asStructure'))
		delete asStructure['_id']
	
		if (JSON.stringify(asStructure) !== JSON.stringify(this.qs._explore_default.asStructure)){
			// We are comming from a query even after refresh
			this.ms.prev_endpoint = this.rss.recentSearches[0].searchObj.endpoint
		}
	}

	async removeFacet(key: string, value: string) {
		this.qs.popQP(key, value)
		this.qs.queryParamsObjectToUrl();
	}

	get infoText(): string {

		const l = `ResultsInfo infoText()`

		if (this.numFound === 0) {
			return this.ms.isLoading ? "" : this.ms.translate('search_results_info.no_results')
		}

		let isExploring:boolean = this.ms.endpoint === 'explore'
		this.isExploringGraphs = isExploring && this.end === 0 // what?
		this.isExploringRecords = isExploring && this.end > 0

		// "exploring_n_results": "Exploring %t records",
		// "displaying_n_results": "Displaying %s-%e of %t results %o",
		let label = this.isExploringGraphs ? 'exploring_n_results' : 'displaying_n_results'

		// console.log(`${l}this.ps.getPref('sort') = `, this.ps.getPref('sort'))

		return this.ms.translate(`search_results_info.${label}`)
			.replace('{s}', "" + this.start)
			.replace('{e}', "" + this.end)
			.replace('{t}', ("" + this.ms.numberFormatter.format(this.numFound)))
			.replace('{o}', this.ms.translate("search_results_info." + this.qs.getQP('sort')))
	}

	get editLabel(): string {
		let isExploring = this.ms.endpoint === 'explore'
		let isFromQuery = this.ms.prev_endpoint != null
		return this.ms.translate(`search_results_info.${isExploring && !isFromQuery ? 'refine_your_dataset': 'edit_your_search'}`)
	}

	public editPath(): void {
		let isFromQuery = this.ms.prev_endpoint != null
		let path = isFromQuery ? this.ms.prev_endpoint : this.ms.endpoint
		if(isFromQuery){
			// When refreshing, we need to force to downstream functions to set the url params to the destination endpoint instead of the current one
			this.qs.urlToQueryParamsObject(null, this.ms.prev_endpoint)
		}
		this.qs.queryParamsObjectToUrl(this.ms.makeRoute({ path: path, caller: 'edit_button' }))
	}

	public doBack(): void {
		this.ms.setLoading(true);
		this.lc.back()
	}

	public displaySaveBox(){
		if(this.shouldSaveDisplay === 'none')
			this.shouldSaveDisplay = 'block'
		else
			this.shouldSaveDisplay = 'none'
	}

	get windowWidth(): number {
		return window.innerWidth
	}
	
	execute_save(){
		let uid = this.rss.buildUid(this.qs.getQP("*"))
		this.rss.save(uid, this.newSearchName)
		this.shouldSaveDisplay = 'none'
	}


	_apply_mapping(params:{}): {}{
		let tm_view_params = {
			'criteria': 'C',
			'basicSearch':' '
		}
		for(let key in params){
			if(_tm_mapping[key]){
				if(_tm_mapping[key]['transform']){
					 this[_tm_mapping[key]['transform']](params[key], _tm_mapping[key]['name'], tm_view_params)
					 continue
				}
				if(_tm_mapping[key]['extra']){
					for(let extra_key in _tm_mapping[key]['extra']){
						tm_view_params[extra_key] = _tm_mapping[key]['extra'][extra_key]
					}
					
				}
				if(_tm_mapping[key]['mappings'])
				{	
					if(_tm_mapping[key]['mappings'][params[key]]){
						if( Array.isArray(_tm_mapping[key]['mappings'][params[key]])){
							for(let index in _tm_mapping[key]['mappings'][params[key]]){
								tm_view_params[_tm_mapping[key]['mappings'][params[key]][index]] = params[_tm_mapping[key]['values']]
							}
						}
						else {
							tm_view_params[_tm_mapping[key]['mappings'][params[key]]] = params[_tm_mapping[key]['values']]
						}
					}
				}
				else if (_tm_mapping[key]['values']){
					if (_tm_mapping[key]['values'][params[key]]){
							tm_view_params[_tm_mapping[key]['name']] = _tm_mapping[key]['values'][params[key]]
					}
					else{
						tm_view_params[_tm_mapping[key]['name']] = _tm_mapping[key]['values']['default']
					}
				}
				else {
					if(_tm_mapping[key]['replace']){
						for(let replace_what in _tm_mapping[key]['replace']){
							params[key] = params[key].replaceAll(replace_what, _tm_mapping[key]['replace'][replace_what])
						}
					}
					tm_view_params[_tm_mapping[key]['name']] = params[key]
				}
			}
		}
		return tm_view_params
	}

	tm_date_tranfsormer(value, key, to_return){
		let from = ''
		let to = ''
		if(value[0] == 'F'){
			from = value.substring(1,'0000-00'.length) + '-01';
			to = value.substring('0000-00'.length + 2, value.length) + '-01';
		}
		else{
			from = value + '-01-01'
			to =formatDate(new Date(), 'yyyy-MM-dd', 'en-us')
		}
		to_return[key+'From'] = from
		to_return[key+'To'] = to
	}

	tmviewButtonClicked() {
		let params = this._parseQueryStringToDictionary(window.location.search)
		let tm_view_params = this._apply_mapping(params)
		let url = tm_view_base_url
		for( let key in tm_view_params){
			url += key + '=' + tm_view_params[key] + '&'
		}
		url = url.substring(0, url.length - 1);
		window.open(url, '_blank').focus();
	}

	_parseQueryStringToDictionary(queryString: string):{} {
	var dictionary = {};
	
	// remove the '?' from the beginning of the
	// if it exists
	if (queryString.indexOf('?') === 0) {
		queryString = queryString.substring(1);
	}
	
	// Step 1: separate out each key/value pair
	var parts = queryString.split('&');
	
	for(var i = 0; i < parts.length; i++) {
		var p = parts[i];
		// Step 2: Split Key/Value pair
		var keyValuePair = p.split('=');
		
		// Step 3: Add Key/Value pair to Dictionary object
		var key = keyValuePair[0];
		var value = keyValuePair[1];
		
		// decode URI encoded string
		value = decodeURIComponent(value);
		value = value.replace(/\+/g, ' ');
		if (value){
			if(dictionary[key]){
				dictionary[key] = dictionary[key] + ','+ value
			}
			else{
				dictionary[key] = value;
			}
		}
	}
	
	// Step 4: Return Dictionary Object
	return dictionary;
}
	

}
