import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from "../../environments/environment"
import { MechanicsService } from './mechanics.service';

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	constructor(private oauthService: OAuthService,
		public ms: MechanicsService,
		public router: Router
	) { }

	async doSSO(): Promise<string> {

		const l = `AuthService doSSO() - `

		if(this.ms.isLocalHost){
			// Too many errors in the console on localhost, cleaning up
			// console.log(`${l}Localhost : skipping doSSO()`)
			return
		}

		// https://www.npmjs.com/package/angular-oauth2-oidc
		this.oauthService.configure(authConfig);
		this.oauthService.tokenValidationHandler = new JwksValidationHandler();
		this.oauthService.setupAutomaticSilentRefresh();

		/*

			// Display all events --> Yeah no, too many useless logs

			this.oauthService.events.subscribe((e) => {
				console.debug(`${l} oauth/oidc event : `, e.type);
			});
		*/

		// capture login event from wipo bar
		document.addEventListener('wipoLoginClick', function (e) {
			// oath module will theoretically redirect to this Uri
			// after successful login
			this.oauthService.initImplicitFlow(encodeURIComponent(this.router.url))
		}.bind(this));

		// capture logout event from wipo bar
		document.addEventListener('wipoLogoutClick', function (e) {
			this.oauthService.logOut();
		}.bind(this));

		try {
			await this.oauthService.loadDiscoveryDocumentAndTryLogin();

			// user not logged in
			if (!this.oauthService.hasValidIdToken()) {
				// cleean up localStorage
				this.oauthService.logOut(true);
			}
		} catch (err) {
			// console.error(`${l}wipoAuthService: initialize: caught error`, err);
			// clean up localStorage
			this.oauthService.logOut(true);
		}

		let state = this.oauthService.state // user just logged in and the state is stored in the initImplicitFlow
			? decodeURIComponent(this.oauthService.state)
			: window.location.pathname + window.location.search

		// console.log(`${l}state = `, state)

		return state
	}

	/*
		get hasValidToken() { return this.oauthService.hasValidIdToken(); }
		get accessToken() { return this.oauthService.getAccessToken(); }
		get identityClaims() { return this.oauthService.getIdentityClaims(); }
		get idToken() { return this.oauthService.getIdToken(); }

		getUserDetails() {
			// console.log('xxxxxxxxxx getUserDetails')
			// console.log('accessToken', this.accessToken)
			// console.log('identityClaims', this.identityClaims)
			// console.log('idToken', this.idToken)
			// console.log('accessToken decoded', jwt_decode(this.accessToken))
		}
	*/
}

