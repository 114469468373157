import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WOption } from '@wipo/w-angular/shared';
import { Subscription } from 'rxjs';

import { DBInfoSolrResponse } from '../../interfaces';
import { SearchService } from '../../_services/search.service';
import { MechanicsService } from '../../_services/mechanics.service';
import { QueryParamsService } from '../../_services/queryParams.service';
import { asStructure2bricks, bricks2AsStructure, canSearch } from '../../utils';
import { OptionsListService } from '../../_services/options-list.service';
import fals from 'fals';

@Component({
	selector: 'page-similarname',
	templateUrl: './page-similarname.component.html',
	styleUrls: ['./page-similarname.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class PageSimilarNameComponent implements OnInit {

	public design_classes: WOption[] = []
	public bricks;
	public classLabel:String = ""
	public canSerachClass:Boolean = false


	constructor(private ar: ActivatedRoute,
		public ss: SearchService,
		public ms: MechanicsService,
		public qs: QueryParamsService,
		public ols: OptionsListService) {

		const l = `page-similarName constructor - `

		this.ms.setEndpoint("similarname")
		// this.qs.resetQP("similarname");
	}

	ngOnInit(): void {

		const l = `pageSimilarName ngOnInit() - `
		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC
		this.qs.keepOnlySearchParams();

		this.buildStuff()
	}

	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
	}

	get defaultBricks() {
		return {
			productIndication: "",
			description: "",
			applicant: "",
			designer: "",
			designation: [],
			designClassType: "",
			designClassValue: ""
		}
	}

	classChange(){
		this.classLabel = this.ms.translate(`page_similarname.${this.bricks.designClassType}`) + " " + this.ms.translate(`page_similarname.value`) ;
		this.canSerachClass = true
	}

	buildStuff() {

		const l = `page-similarName buildStuff() - `

		this.bricks = this.defaultBricks;
		this.design_classes = [
			{ value: "locarno", label: "" }, // Displayed as "Exact similarity" but in fact it acts as a "contains". Leaving the value "Exact", but changing the displayed text to "Contains"
			{ value: "ca", label: "" },
			{ value: "jp", label: "" },
			{ value: "us", label: "" },
		].map(obj => {
			obj.label = this.ms.translate(`page_similarname.${obj.value}`);
			return obj
		})

		this.buildBricksFromQP()
		if(this.bricks.designClassType){
			this.classLabel = this.ms.translate(`page_similarname.${this.bricks.designClassType}`) + " " + this.ms.translate(`page_similarname.value`) ;
			this.canSerachClass = true
		}
		else{
			this.classLabel = this.ms.translate(`page_similarname.search_class_value`) + " " + this.ms.translate(`page_similarname.value`) ;
		}

	}

	buildBricksFromQP() {
		const l = `page-similarName buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")
		// console.log(`${l}type=${typeof qpAsStructure } - qpAsStructure = `, qpAsStructure)
		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {
			this.bricks = Object.assign(this.bricks, asStructure2bricks(JSON.parse(qpAsStructureString)))
		}
	}


	get canSearch(): boolean {
		return canSearch(this.bricks.productIndication) || canSearch(this.bricks.description) || canSearch(this.bricks.applicant) || canSearch(this.bricks.designer) || canSearch(this.bricks.designation) || (canSearch(this.bricks.designClassType) && canSearch(this.bricks.designClassValue))
	}

	async search() {

		const l: string = `PageSimilarName search() - `

		if (!this.canSearch) return;

		const asStructure = bricks2AsStructure(this.bricks);

		// this.qs.setQP("asStructure", JSON.stringify(asStructure)) // Why the hell was I stringifying the asStructure??
		this.qs.setQP("asStructure", asStructure)
		this.qs.setQP("fg", "_void_")

		// console.log(`01 - ${l}HAve set QP.asStructure =`, asStructure)

		const route: string = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'results', caller: l })

		// Updates the URL accordingly without reloading the page.
		// Changing the query params in the URL will trigger a subscription in HOCSearchComponent ,
		// which in turn will automatically call SearchService.search() and refresh the results.
		await this.qs.queryParamsObjectToUrl(route)
	}

	doReset() {
		const l = `page-similarname doReset() - `
		this.bricks = this.defaultBricks;
	}

}
