import { NgZone } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	QueryParamsClassified,
	ExploreQueryParams,
	SearchQueryParams,
} from '../interfaces';
import { bricks2AsStructure, deepClone, generateId, resizeImage } from '../utils';
import { MechanicsService } from './mechanics.service';
import { PreferencesService } from './preferences.service';

@Injectable({
	providedIn: 'root'
})
export class QueryParamsService {

	public queryParams: any = {
		quicksearch: this._quicksearch_default,
		similarlogo: this._similarlogo_default,
		similarname: this._similarname_default,
		goodsservices: this._goodsservices_default,
		explore: this._explore_default,
		advancedsearch: this._advancedsearch_default
	}

	// these query params are to be set as arrays
	// since they correspond to multi-value selects in search forms
	public multivalueQP = [
		'designation',
		'office', // --> Jer : Ah great, so brandName is a string, but office is an array?
		'applicantCountryCode',
		"status"
	]

	public cache: any = {}; // cache for queryParams2Human

	constructor(public ms: MechanicsService,
		public ps: PreferencesService,
		public ar: ActivatedRoute,
		public ngZone: NgZone,
		public router: Router) {

		const l = `QS constructor() - `

		if (this.ms.isLocalHost || this.ms.isAwsDev) {
			// console.log(`[DEV] QS constructor - making window.queryParams available`)
			window["queryParams"] = this.queryParams // for dev and Cypress testing
		}

		this.urlToQueryParamsObject() // At startup, reading the URL and transforming its queryParams into an object, so as to prefill the pages (in case of a page reload, for instance)
	}

	get _quicksearch_default(): SearchQueryParams {
		return {
			sort: this.ps.getPref("sort", "quicksearch"),
			start: 0,
			rows: Number(this.ps.getPref("rows", "quicksearch")), // use the pref values
			asStructure: {
				boolean: "AND",
				bricks: []
			}
		}
	}

	get _similarname_default(): SearchQueryParams {
		return {
			sort: "score desc",
			start: 0,
			rows: Number(this.ps.getPref("rows", "similarname")), // use the pref values
			asStructure: {
				boolean: "AND",
				bricks: []
			}
		}
	}

	get _similarlogo_default(): SearchQueryParams {
		return {
			strategy: "concept",
			sort: "score desc",
			start: 0,
			rows: Number(this.ps.getPref("rows", "similarlogo")), // use the pref values,
			asStructure: {
				boolean: "AND",
				bricks: []
			}
		}

	}

	get _goodsservices_default(): SearchQueryParams {
		return {
			sort: "score desc",
			start: 0,
			rows: Number(this.ps.getPref("rows", "goodsservices")), // use the pref values,
			asStructure: {
				boolean: "AND",
				bricks: []
			}
		}

	}

	get _advancedsearch_default() {
		return {
			sort: "score desc",
			strategy: "concept",
			rows: 30,
			asStructure: {
				_id: generateId(),
				boolean: "AND",
				bricks: [
					// { _id: generateId(), key: "niceClass", /* strategy: "Range",*/  value: [] },
					{ _id: generateId(), key: "productIndication", strategy: "Exact", value: "" },
					// { _id: generateId(), key: "status", /* strategy: "Range",*/  value: [] },
					// { _id: generateId(), key: "appDate", /* strategy: "all_of", */  value: [] },
				]
			}
		}
	}

	get _explore_default(): ExploreQueryParams {
		return {
			sort: this.ps.getPref("sort", "explore"),
			start: 0,
			rows: 0,
			asStructure: {
				boolean: "AND",
				bricks: []
			}
		}
	}



	// -----------------------------
	// start: queryParams Accessors
	// -----------------------------

	// Utility to easily get any queryParams, for instance from a template. ps.getQP('layout') == 'grid'
	getQP(which: string = '*', endpoint: string = this.ms.endpoint) {

		const l: string = `QS getQP() - `

		// if (!this.queryParams[endpoint]) this.resetQP(endpoint) --> I shouldn't have to do that. QueryPArams object is defined, initialized, and should remain defined and initialized. This is a dirty workaround, I'm working on finding what's going on.

		// console.log(`${l}this.queryParams[${endpoint}] = `, deepClone(this.queryParams[endpoint]))
		// if(which!=="*") // console.log(`${l}this.queryParams[${endpoint}][${which}] = `, deepClone(this.queryParams[endpoint][which]))

		try {
			return which === '*' ? this.queryParams[endpoint] : this.queryParams[endpoint][which]
		} catch (err) {
			console.error(`${l}Could not return queryParams['${endpoint}']['${which}']`);
			return null
		}
	}

	getFP(which: string = '*') {
		let queryParams = which === '*' ? this.getQP(which) : this.getQP(`fc${which}`)

		let facetParams = {};
		// get only params starting with fc
		Object.keys(queryParams).forEach((key) => {
			if (/^fc/.test(key))
				facetParams[key.replace('/^fc/', '')] = queryParams[key]
		});
		return facetParams
	}

	get keys(): string[] {

		const l = `qs get keys()`

		// console.log(`${l}this.queryParams[${this.ms.endpoint}]=`, deepClone(this.queryParams[this.ms.endpoint]))

		return Object.keys(this.queryParams[this.ms.endpoint]).filter(
			key => !!this.queryParams[this.ms.endpoint][key]
		)
	}

	resetQP(endpoint: string = '*', caller?: string): void {

		const l = `QS.resetQP() - `

		// console.log(`${l}endpoint='${endpoint}', caller='${caller}'`)

		if (endpoint === '*') {
			let endpoints = ['quicksearch', 'similarlogo', 'similarname', 'goodsservices', 'explore']
			endpoints.forEach(endpoint => this.resetQP(endpoint, "resetQP recursive"))
		} else {
			this.queryParams[endpoint] = deepClone(this[`_${endpoint}_default`]);
			// console.log(`${l}Have reset queryParams[${endpoint}] to : `, deepClone(this.queryParams[endpoint] ))
		}
	}

	setQP(which: string, value: string | number | string[] | number[] | boolean, endpoint: string = this.ms.endpoint): void {

		const l: string = `QS setQP() - `

		// console.log(`${l}'${which}':`, value)

		this.queryParams[endpoint] = this.queryParams[endpoint] || {};

		let isMultiValueQP: boolean = which.startsWith('fc') || this.multivalueQP.includes(which);

		let toSet: any = (isMultiValueQP && !Array.isArray(value)) ? [value] : <any>value;

		// canceling the automatic casting to string of booleans
		if (toSet === "true") toSet = true;
		if (toSet === "false") toSet = false;

		if ((typeof (toSet) === "string") && toSet.length === 0) {
			this.rmQP(which, endpoint)
		} else {
			// console.log(`${l}Setting QP '${which}'=`, toSet)
			this.queryParams[endpoint][which] = toSet
		}
	}

	rmQP(which: string, endpoint: string = this.ms.endpoint): void {

		// rmQP removes a QueryParam.
		// popQP removes an item from a QueryParam array.

		const l = `QS rmQP() - `

		try {
			delete this.queryParams[endpoint][which]
		} catch (err) {
			if (this.ms.isLocalHost) console.warn(`${l}Could not delete queryParams[${endpoint}][${which}], it's probably not a problem as it was undefined in the first place`);
		}
	}

	popQP(which: string, valueToRemove: string | number): void {

		// rmQP removes a QueryParam.
		// popQP removes an item from a QueryParam array.

		const l = `QS popQP() - `

		let prevValue = this.getQP(which)

		if (prevValue === valueToRemove) {
			return this.rmQP(which)
		}

		if (!Array.isArray(prevValue)) {
			console.warn('Removing what does not exits.', valueToRemove, prevValue)
			return
		}

		if (!prevValue.includes(valueToRemove)) {
			console.warn('Removing what does not exits.', valueToRemove, prevValue)
			return
		}

		let newValue = prevValue.filter(v => v !== valueToRemove)

		this.setQP(which, newValue)
	}


	// clear the facets - called by the Facets component
	public clearAllFacets(): void {
		// remove the facets
		for (let key of this.keys) {
			if (/^fc/.test(key)) this.rmQP(key)
		}
	}

	public keepOnlySearchParams(clearFacets: boolean = true): void {
		// reduce queryParams to only those that correspond to search params
		// called by OnInit of search forms


		if (clearFacets) this.clearAllFacets()

		// remove the modifiers
		for (let key of this.keys) {
			if (['start', '_', 'i'].includes(key)) this.rmQP(key)
		}
		// rewrite the url
		this.queryParamsObjectToUrl()
	}


	toPayload(endpoint: string = this.ms.endpoint, exclude: string[] = []): any {

		const l = `QS paramsToPayload - `

		// always send to solr the preferences for sorting and number of rows
		// to avoid to also define default is solr

		let prev_endpoint = this.ms.prev_endpoint

		let payload = deepClone(this.queryParams[endpoint]);

		// set the defaults for the query from preferences
		if (!payload.rows) payload.rows = this.ps.getPref("rows", endpoint)
		if (!payload.sort) payload.sort = this.ps.getPref("sort", endpoint)

		exclude = exclude.concat(['_', 'i']);

		payload = Object.keys(payload)
			.filter(k => !!payload[k] && !exclude.includes(k))
			.reduce((a, k) => ({ ...a, [k]: payload[k] }), {});


		if (!!payload.strategy
			&&
			(
				endpoint === "similarlogo"
				||
				(endpoint === "advancedsearch" && payload.sort === "image_similarity")
				|| 
				(endpoint === "explore" && (prev_endpoint === 'similarlogo' || prev_endpoint === "advancedsearch" && payload.sort === "image_similarity"))
			)
			
		) {

			// there is an image uploaded
			if (this.ms.bases64?.length) {
				payload.bases64 = this.ms.bases64resized || this.ms.bases64;
			} else {
				delete payload.strategy
				delete payload.bases64
			}
		}

		// console.log(`${l}346 returning payload = `, payload)

		return payload
	}

	queryParams2Object(queryParams: any = this.getQP('*')): QueryParamsClassified {

		// Takes a queryParams object, and splits its keys between "query" and "facets"

		const l = `qs.queryParams2Object() - `

		let queryParamsClassified: QueryParamsClassified = {
			'query': {},
			'facets': {}
		}

		Object.keys(queryParams)
			.filter(key => !!queryParams[key] && !['_', 'i', 'rows', 'start', 'sort', 'fg', 'endpoint', 'format'].includes(key))
			.forEach(key => {
				if (/^fc/.test(key)) {
					queryParamsClassified.facets[key] = queryParams[key]
				} else {
					queryParamsClassified.query[key] = queryParams[key]
				}
			})

		// add the image base64 if there --> Jer : Uh? Why?
		if (['similarlogo', 'advancedsearch'].includes(this.ms.endpoint) && !!queryParams.strategy && this.ms.bases64?.length) {
			queryParamsClassified['bases64'] = this.ms.bases64
		}

		return queryParamsClassified
	}

	urlToQueryParamsObject(queryParams?: any, endpoint: string = this.ms.endpoint): void {

		const l = `QS url2QueryParams() - `

		// reads query Params from URL and stores them into this.queryParams[endpoint]

		// ineffective call as the router has not
		// yet initialized itself
		if (!endpoint) return

		if (!queryParams) {
			/*
				Usually, this function is called whenever the URL changes (search.component URL watcher subscription).
				But it should also be called at startup, when reloading the page for instance, so as to read the URL and convert the URL parameters into a queryParams object.
				In this case, no queryParams are passed, we must read them from the URL
			*/
			try {
				const urlParams = window.location.search.replace(/^\?/, "");
				// console.log(`${l}urlParams='${urlParams}'`)
				const searchParamsObj = new URLSearchParams(urlParams)

				queryParams = {};
				for (let key of searchParamsObj.keys()) {
					// console.log(`${l}`, key, ` = `, searchParamsObj.getAll(key))

					let value: string | string[] = <string[]>searchParamsObj.getAll(key)// there can be multiples : designation=FR&designation=DE, fsstatus=Expired&fcStatus=Registered, etc. which are returned as ["FR","DE"]

					if (value.length === 1) value = value.pop(); // brandName=["apple"] ---> brandName="apple"

					queryParams[key] = value
				}

				// console.log(`${l}424 serialized queryParams from URL = `, deepClone(queryParams))
			} catch (err) {
				console.warn(`${l}Caught error - Could not transform URL params into queryParams object! err=`, err)
			}
		}
		if (!queryParams.asStructure && !(endpoint == 'reports') ) { // We got a legacy format, from TMview or WIPO Portal. It has to be converted into an asStructure
			const asStructure = bricks2AsStructure(queryParams)
			//console.log(`${l}429 queryParams after transformation = `, deepClone(queryParams))
			this.setQP("asStructure", asStructure)
			this.queryParamsObjectToUrl(window.location.pathname) // Reloading the page with the new queryParams transformed into asStructure
			return;
		}
		// Mona : reset the previous queryParams obj to defaults
		this.resetQP(endpoint, l)

		// now set the new ones
		const keys = Object.keys(queryParams)

		for (let key of keys) {
			// console.log(`${l} setting key='${key}', value=`, queryParams[key])
			// setQP will take care of fc and multivalues
			this.setQP(key, queryParams[key], endpoint); // "fcstatus": "Registered" --> "fcstatus": ["Registered"]
		}
	}

	async queryParamsObjectToUrl(url?: string, forceReplace: boolean = false): Promise<void> {

		const l: string = `qs.queryParams2Url() - `

		/*
			Updates the URL accordingly without reloading the page. Used when clicking a facet.
			Changing the query params in the URL will trigger a subscription in HOCSearchComponent, which in turn will automatically call SearchService.search() and refresh the results.
		*/

		// console.log(`${l}passed url = `, url)

		// Little hack to give Angular some time to register the checkbox true/false in the queryParams before moving on
		await new Promise(r => setTimeout(r))


		if (!url) {
			/*
				Mona : this is to solve the irritating bug.
				the router was not picking a change in queryParam if a value in an array was added/removed:
				fcStatus = undefined => fcStatus = ['Registered'] => picked up
				fcStatus = ['Registered'] => fcStatus = [] => not picked up
				
				This is a hack to always change a value in the query params and trigger a router navigation
				and trigger all the subscribers route.queryParams.subscribe
			
				Jer : I don't think this is needed any longer, because all search params are contained in asStructure? --> Update : yes, it is needed, at least on the Graphs page. When adding/removing facets, the URL change isn't triggered, so we still need this dummy _ URL param
			*/

			this.setQP("_", Date.now());
		}


		this.ngZone.run(() => {

			const navigateTo: string[] = url ? [url] : [];
			let queryParams: any = this.getQP();

			// There is a bug in Angular router that serializes the asStructure to "[object Object]" in the query params. I need to stringify it if I don't want it destroyed
			// Bug : https://github.com/angular/angular/issues/47307
			if (queryParams.asStructure && typeof (queryParams.asStructure) === "object") {
				try {
					queryParams.asStructure = JSON.stringify(queryParams.asStructure)
				} catch (err) {
					console.warn(`${l}Could not stringify the asStructure in order to pass it to Angular router. queryParams.asStructure=`, deepClone(queryParams.asStructure))
				}
			}

			//console.log(`${l}navigating to : '${navigateTo}', ${queryParams.asStructure} ${queryParams['_']}`)

			this.router.navigate(navigateTo, {
				relativeTo: this.ar,
				queryParams,
				replaceUrl: forceReplace ? true : url ? false : true,
			})
		});

		return Promise.resolve()
	}




	removeFacet(key: string, val: string): void { // key="status", val="Registered"

		const l = `qs.removeFacet() - `


		// console.log(`${l}Popping '${key}.${val}'`);

		if (!key.startsWith("fc")) key = `fc${key}`;


		this.popQP(key, val) // Does not trigger the search nor update the URL...
		this.queryParamsObjectToUrl() // ... so I'm triggering it here
	}

}


