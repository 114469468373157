import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { WOption } from '@wipo/w-angular/shared';
import { DBInfoSolrResponse, SuggestSolrResponse, DBNews } from '../../interfaces';
import { MechanicsService } from '../../_services/mechanics.service';
import { QueryParamsService } from '../../_services/queryParams.service';
import { PreferencesService } from '../../_services/preferences.service';
import { SearchService } from '../../_services/search.service';
import { RecentSearchesService } from '../../_services/recentSearches.service';
import { asStructure2bricks, bricks2AsStructure, canSearch, deepClone } from '../../utils';
import { OptionsListService } from '../../_services/options-list.service';


@Component({
	selector: 'page-quicksearch',
	templateUrl: './page-quicksearch.component.html',
	styleUrls: ['./page-quicksearch.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class PageQuickSearchComponent implements OnInit {

	public bricks = {
		productIndicationStrategy: "Simple",
		applicantStrategy: "Simple",
		numberStrategy: "Either",

		v: "", // Generic search field, will be transformed into, applicant or number, depending what we search for
		searchBy: "productIndication"
	}


	public optionsSearchBy: WOption[] = []

	// need translation
	// public helpSearch: any = {

	//	 "applicant": `${this.ms.translate("general_words.example")}: wipo, wip<b>*</b>, w<b>?</b>po, world <b>AND</b> <b>(</b>intell* <b>OR</b> property<b>)</b>`,

	//	 "number": `${this.ms.translate("general_words.example")}: 123456, 1234<b>*</b>, XX/Y/12345`
	// }
	public helpSearch: any = {
		"productIndication": `Ex: wipo, wip<b>*</b>, w<b>?</b>po`,
		"applicant": `Ex: wipo, wip<b>*</b>, w<b>?</b>po`,
		"number": `Ex: 123456, 1234<b>*</b>, XX/Y/12345, US501995075004828 ...`,
		"combined": `Ex: wipo, wip<b>*</b>, w<b>?</b>po`,
	}

	public recentSearches: any[] = [];

	public recordsCount: string = null
	public news: DBNews[] = [];
	public dataSourcesCount: string = null

	public display_save_obj: any = {};
	public newSearchName: string = '';


	constructor(private ar: ActivatedRoute,
		public ms: MechanicsService,
		public ps: PreferencesService,
		public ss: SearchService,
		public rss: RecentSearchesService,
		public qs: QueryParamsService,
		public ols: OptionsListService) {

		const l = `page-quicksearch constructor() - `

		this.ms.setEndpoint("quicksearch")
		// this.qs.resetQP("quicksearch", l);

	}

	async ngOnInit(): Promise<void> {
		const l = `pageQuickSearch ngOnInit() - `

		this.ms.setOffice(this.ar.snapshot.params.office) // ex: IPO-FR - only sets ms.officeCC

		// console.log(`${l}queryParams = `, this.qs.getQP())

		// if coming back to this page from results page
		// remove facets
		this.qs.keepOnlySearchParams();

		this.buildStuff();
		this.getCounts();
		this.getNews();
		for (let i = 0; i < this.rss.recentSearches.length; i++) {
			this.display_save_obj[this.rss.recentSearches[i]['uid']] = 'none'
		}
	}
	get windowWidth(): number {
		return window.innerWidth
	}

	ngOnDestroy() {
		this.ms.unsetEndpoint()
		this.ms.unsetSearchError()
	}

	buildStuff() {
		this.buildOptions()
		this.buildBricksFromQP()
	}

	buildBricksFromQP() {
		const l = `page-quickSearch buildBricksFromQP() - `
		const qpAsStructureString: string = this.qs.getQP("asStructure")
		// console.log(`${l}type=${typeof qpAsStructureString } - qpAsStructure = `, qpAsStructureString)

		if (qpAsStructureString?.length && qpAsStructureString?.length > 2) {
			const parsedStructure = JSON.parse(qpAsStructureString);
			this.bricks = Object.assign(this.bricks, asStructure2bricks(parsedStructure))
			this.bricks.v = parsedStructure.bricks[0]?.value; // transforming number/applicant/combined into the generic "v"
		}		
	}

	get_news_for_lang(value?): string {
		return value ? (value[this.ms.lang] || value["en"]) : ''
	}

	buildOptions() {
		this.optionsSearchBy = [
			{ value: "productIndication", label: "" },
			{ value: "applicant", label: "" },
			{ value: "number", label: "" },
			{ value: "combined", label: "" }
		]
	}



	async getNews() {

		const l: string = `quickSearchComponent getNews() - `

		try {
			let news: DBNews[] = await this.ss.getDbNews()

			this.news = news.slice(0, 1).map(n => {
				n["news_date"] = this.ms.dateFormatterHuman.format(new Date(n["news_date"]));
				return n
			})
		}
		catch (err) {
			// console.log(`${l}Caught error : `, err)
		}
	}

	display_save(uid) {
		this.newSearchName = ''
		for (let i = 0; i < this.rss.recentSearches.length; i++) {
			this.display_save_obj[this.rss.recentSearches[i]['uid']] = 'none'
		}
		this.display_save_obj[uid] = 'block'
	}


	execute_save(uid) {
		this.rss.save(uid, this.newSearchName)
		for (let i = 0; i < this.rss.recentSearches.length; i++) {
			this.display_save_obj[this.rss.recentSearches[i]['uid']] = 'none'
		}
	}

	get icon_save(): string { // Jer : dafuk?
		return `<span class="save"> </span>`
	}

	get isComplexQuery() : boolean{
		return /( AND | OR )/.test(this.bricks.v || "")
	}

	async getCounts() {

		const l: string = `quickSearchComponent getDbInfo() - `

		let dbinfo: DBInfoSolrResponse = await this.ols.getDbInfo({ caller: l })

		this.recordsCount = "" + dbinfo?.facets.count
		this.dataSourcesCount = "" + dbinfo?.facets?.office.buckets.length
	}


	get welcomeSpeech(): string {
		let office = this.ms.officeCC
		// the search engine is configured for an office
		if (office) {
			const welcome_msg = 'page_quick_search.welcome_speech_peroffice';

			return this.ms.translate(welcome_msg)
				.replace('{r}', this.ms.numberFormatter.format(this.recordsCount))
				.replace('{c}', this.ms.translate('designation.' + office))
				.replace('{l}', this.ms.translate('office.' + office))
		}

		return this.ms.translate('page_quick_search.welcome_speech')
			.replace('{r}', this.ms.numberFormatter.format(this.recordsCount))
			.replace('{d}', this.dataSourcesCount)
	}

	
	get hint_advancedSearch() {

		let toReturn = this.ms.translate("page_quick_search.hint_advancedSearch")
			.replace("<a>", `<a href="/${this.ms.lang}/advancedsearch">`)
		return toReturn
	}
	

	suggest($event: KeyboardEvent) {

		const l: string = `quickSearchComp suggest() - `

		// console.log(`${l}$event = `, $event)

		this.ols.suggest($event, this.qs.getQP("by"))
	}

	onSuggestSelect(value) {
		const l: string = `quickSearchComp onSuggestSelect() - `
		// remove the highlight tag <b></b>
		value = value.replace(/<[^>]*>?/gm, '');
		this.qs.setQP("v", value)
	}

	processLinks(e) {

		const l = `pageQuickSearch processLinks() - `

		// https://stackoverflow.com/questions/55350647/how-to-use-routerlink-with-innerhtml

		const element: HTMLElement = e.target;
		if (element.nodeName === 'A') {
			e.preventDefault();
			const link = element.getAttribute('href');

			// console.log(`${l}Navigating to '${link}'`)

			this.ms.router.navigate([link]);
		}
	}

	get canSearch() {
		return canSearch(this.bricks.v)
	}

	async search() {
		const l: string = `pageQuickSearch search() - `
		// console.log(`${l}`);
		if (!this.canSearch) return;

		// a few transforms before searching

		for (const key of ["productIndication", "applicant", "number"]) delete this.bricks[key];

		let asStructure = {};

		if (this.bricks.searchBy === "combined") {

			const value =  this.bricks.v.trim();

			asStructure = {
				"boolean": "OR",
				"bricks": [
					{
						"key": "productIndication",
						value,
						"strategy": "Simple"
					},
					{
						"key": "applicant",
						value,
						"strategy": "Simple"
					},
					{
						"key": "number",
						value,
						"strategy": "Either"
					},
				]
			}

		} else {
			this.bricks[this.bricks.searchBy] = this.bricks.v.trim(); // Transforming the generic "v" into , applicant or number
			asStructure = bricks2AsStructure(this.bricks)

		}

		this.qs.setQP("asStructure", JSON.stringify(asStructure))
		this.qs.setQP("fg", "_void_")

		this.ms.setLoading(true)

		// this will navigate to results and set the query params and trigger
		// the activatedRoute change with queryParams and launch the search
		// results page and launch the search
		const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: 'results', caller: l })

		await this.qs.queryParamsObjectToUrl(route)
	}

	// Jer : I moved "execRecentSearch" and "openRecentSearch" out of RecentSearchesServices, because they rely on QueryParamsService, and it made a circular dependency (with the silent pagination... it's complicated)

	async execRecentSearch(searchObj: any) {

		const l: string = `pageQuickSearch execRecentSearch() - `

		this._loadRecentSearch(searchObj)

		let subpath = searchObj.endpoint === 'explore' ? 'graphs' : 'results';

		// console.log(`${l}searchObj = `, searchObj)

		let routeElements = { path: this.ms.endpoint, subpath, caller: l };

		if (searchObj.office) {
			/*
				Resolving a very specific bug :
				- Select an office in data coverage, say Algeria (DZ)
				- Make a quick search
				- Select another office, for instance WHO
				- In recent searches, execute the DZ query again

				The problem is, there's /IPO-WHO/ and fcoffice=DZ in the URL, so there's an office conflict.

				In this case, we need to change /IPO-WHO/ to /IPO-DZ/
			*/

			routeElements["office"] = searchObj.office
		}

		const route = this.ms.makeRoute(routeElements)

		// console.log(`${l}made route = '${route}'`)

		await this.qs.queryParamsObjectToUrl(route)
	}

	async openRecentSearch(searchObj: any) {

		const l: string = `pageQuickSearch openRecentSearch() - `

		// console.log(`${l}Loading recent serach = `, searchObj)

		this._loadRecentSearch(searchObj)

		const route = this.ms.makeRoute({ path: this.ms.endpoint, caller: l })

		await this.qs.queryParamsObjectToUrl(route)
	}

	_loadRecentSearch(searchObj: any): void {

		const l = "pageQuickSearch  _loadRecentSearch() - "

		const searchObjClone = deepClone(searchObj)

		// corresponds to recent search
		delete searchObjClone.endpoint;

		// console.log(`${l}searchObj.endpoint = `,searchObj.endpoint)

		this.ms.setEndpoint(searchObj.endpoint)
		this.qs.queryParams[searchObj.endpoint] = searchObjClone;
		if (searchObj.bases64){
			this.ms.bases64 = searchObj.bases64
		}
		this.ms.setEndpoint(searchObj.endpoint)

	}

}

