import { ChangeDetectorRef, Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from "../environments/environment"
import { AuthService } from './_services/auth.service';
import { MechanicsService } from './_services/mechanics.service';
import { OptionsListService } from './_services/options-list.service';
import { PreferencesService } from './_services/preferences.service';
import { QueryParamsService } from './_services/queryParams.service';
import { RecentSearchesService } from './_services/recentSearches.service';
import { ReportsService } from './_services/reports.service';
import { SearchService } from './_services/search.service';

declare const SmartlingContextTracker

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent {

	public environment = environment


	public logsSettings: any = {
		isExists: !this.ms.isAwsProd,
		align: "left",
		minimize: !window["Cypress"] || false // I found out that minimizing the logs messes up the view during Cypress testing, so I expand them during testing
	}

	public helpOptions: any[] = [];
	public helpMore: any[] = [];

	constructor(
		private router: Router,
		public ms: MechanicsService,
		public ss: SearchService,
		public rss: RecentSearchesService,
		public ps: PreferencesService,
		public qs: QueryParamsService,
		public rs: ReportsService,
		private authService: AuthService,
		public ols:OptionsListService,
		private changeDetector: ChangeDetectorRef) {
	}

	async ngOnInit() {

		const l = `app.component ngOnInit() - `

		if (this.ms.isAwsAcc) this.loadSmartlingContext() // only on AWS acceptance

		let state: string = window.location.pathname + window.location.search;

		this.buildHelpMenu()

		if (!this.ms.isLocalHost) {
			this.loadWipoNavbar()
			state = await this.authService.doSSO(); // Too many errors in the console, I'm cleaning this up
		}

		// console.log(`${l}state=`, state)

		this.router.navigateByUrl(state, {
			skipLocationChange: false,
			replaceUrl: true
		})

		const logsSettingsString = localStorage.getItem("gbd.logsSettings");

		if (logsSettingsString) {

			try {
				let logsSettings = JSON.parse(logsSettingsString);
				this.logsSettings = Object.assign(this.logsSettings, logsSettings)
				// console.log(`${l}this.logsSettings=`,this.logsSettings)

			} catch (err) {
				// console.log(`${l}Could not restore logs settings, ignoring - Full err =`, err)
			}
		}
	}

	loadWipoNavbar(): void {

		const l = `appcomponent loadWipoNavbar() - `

		const wipoComponentsPath: string = environment.wipoComponentsPath;

		if (!wipoComponentsPath) {
			// console.log(`${l}No wipoComponentsPath, not loading the WIPO navbar`)
			return
		}

		// console.log(`${l}wipoComponentsPath : `, wipoComponentsPath);

		[
			`${wipoComponentsPath}/polyfills/webcomponents-loader.js`,
			`${wipoComponentsPath}/wipo-init/wipo-init.js`,
			`${wipoComponentsPath}/wipo-navbar/wipo-navbar.js`
		].forEach(src => {
			document.head.appendChild(Object.assign(document.createElement('script'), {
				src,
				async: true
			}));
		});
	};


	async buildHelpMenu() {

		const l = `appcomponent buildHelpMenu() - `
		let antiloop = 0
		// console.log(`${l}Rebuilding help menu`)
		while (!this.ms.translations && antiloop < 50) {
			await new Promise(r => setTimeout(r, 200));
			antiloop++;
		}

		this.helpOptions = [  // Optional, set custom links for Contact and FAQ  (default = WIPO CONTACT)
			{
				"code": "contact", // - code property is mandatory
				"link": `https://www3.wipo.int/contact/${this.ms.lang}/area.jsp?area=designdb` // - link property is optional
			},
			{
				"name": "FAQs",
				"code": "faq",
				"link": environment.FAQUrl.replace("{lang}", this.ms.lang),
				"targetTab": "_self"
			},
		];

		this.helpMore = [
			{
				"name": this.ms.translate("help_menu.news"),
				"code": "news",
				"link": `${environment.appUrl}/${this.ms.lang}/news`,
				"targetTab": "_self"
			},
			{
				"name": this.ms.translate("help_menu.user_reference_guide"),
				"code": "guide",
				"link": `${environment.appUrl}/${this.ms.lang}/help`,
				"targetTab": "_self"
			},

			{
				"name": this.ms.translate("help_menu.terms_of_use"),
				"code": "terms",
				"link": `https://www.wipo.int/reference/${this.ms.lang}/designdb/terms_and_conditions.html`,
				"targetTab": "_self"
			},

		];
	}

	loadSmartlingContext() {
		var script = document.createElement('script');
		script.onload = function () {
			SmartlingContextTracker.init({
				orgId: 'LR3HwdNcfBVS269bS54AUQ',
				host: 'api.smartling.com'
			});
		};
		script.src = "//d2c7xlmseob604.cloudfront.net/tracker.min.js";
		document.getElementsByTagName('head')[0].appendChild(script);
	}

	get windowWidth(): number {
		return window.innerWidth
	}

	setLogsSetting(key: string, value: any) {

		const l = `appComponent setLogsSetting() - `

		// console.log(`${l}setting logsSettings[${key}] = ${value}`)

		this.logsSettings[key] = value;

		localStorage.setItem("gbd.logsSettings", JSON.stringify(this.logsSettings))
	}

	toggleDummyLogos($event) {

		const l = `compResultsInfo toggleDummyLogos() - `

		const isChecked: boolean = $event.target.checked

		// console.log(`${l}isCheckd = `, isChecked)

		localStorage.setItem("showDummyImages", "" + isChecked)

	}

	get isDummyLogos() {
		return localStorage.getItem("showDummyImages") === "true"
	}
}

