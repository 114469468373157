
/*

	This is loaded inside an iFrame!

	On clicking "PDF export", an URL is built (/en/quicksearch/export?by=brandName&fg=__void__&rows=15&sort=applicationDate%20desc&v=cherry&format=PDF)

	which is set as an iFrame source (iframe#resultsExport in results component), in which the app is loaded and this component is executed. It loads the results and exports them as PDF

*/



// Angular core
import { formatDate } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Interfaces
import {IDXDocument } from '../../../interfaces';

// Services
import { MechanicsService } from '../../../_services/mechanics.service';
import { QueryParamsService } from '../../../_services/queryParams.service';

// Libraries
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js"
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
// fails to compile : import * as ExcelJS from "exceljs";

// Other imports
import { wipoImage } from "../../../_imports/wipoImage"
import { getImageDimensions } from 'src/app/utils';

@Component({
	selector: 'export',
	templateUrl: './export.component.html',
	styleUrls: ['./export.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class ExportComponent implements OnInit {

	public docs: IDXDocument[] = []

	public numFound: number = 0
	public end: number = 0
	public start: number = 1

	public searchResultUpdateTime: number;

	public executionDate: string = ''
	private fileName: string = ''
	private endpoint: string;

	public isReport = false;
	public reportName = false;

	// Resultlist Defaults
	public noVerbalElement: string = ''; // to display if !result.human.brandName

	constructor(public ms: MechanicsService,
		private ar: ActivatedRoute,
		private qp: QueryParamsService) {
	}


	ngOnInit(): void {
		const l = `PageExportComponent ngOnInit() - `

		this.endpoint = this.ar.snapshot.params.endpoint;

		this.isReport = this.endpoint === 'reports'
		this.reportName = this.qp.getQP('reportName')

		this.executionDate = this.ms.dateFormatterHuman.format(new Date())

		this.fileName = `GBD-${this.endpoint}_${formatDate(new Date(), 'yyyyMMdd', 'en_US')}`; // "GBD-quicksearch_20221021"

		if (this.isReport) {
			this.fileName = `GBD-${this.endpoint}_${this.reportName}`
		}

		// to display if !result.human.brandName
		this.noVerbalElement = this.ms.translate('search_results.no_verbal_elements')

	}


	setSearchResult(searchResult) {

		const l=`exportComponent setSearchResult() - `

		// console.log(`${l}`)

		// set this to update the results-info
		this.end = searchResult.response.docs.length;
		if(searchResult.facets) this.numFound = searchResult.facets.count;
		this.searchResultUpdateTime = Date.now();

		this.docs = searchResult.response.docs;

		for (let doc of this.docs) {
			doc = this.ms.computeIDXDocumentCustomKeys(doc)
		}

		/* filtering only results with logos, for dev, to see if logos appear in the XLSX / PDF
			if(this.ms.isLocalHost){
				this.docs = this.docs.filter( doc => doc.logo && doc.logo.length)
			}
		*/

		// console.log(`${l}Exporting docs = `, this.docs)

		this.export();
	}

	export(): void {

		const l = `pageResult export() - `

		const format: string = this.qp.getQP("format").toLowerCase();

		switch (format) {
			case "xlsx":
				this.exportAsExcel();
				break;

			case "pdf":
				this.exportAsPDF();
				break;
		}
	}


	exportAsPDF() {

		const element = document.getElementById('pdfExport');

		const opt = {
			margin: [2.4, 1.4, 1.4, 1.4],
			filename: `${this.fileName}.pdf`,
			image: { type: 'jpeg', quality: 0.75 },
			html2canvas: { scale: 2, letterRendering: true, useCORS: true },
			pagebreak: { avoid: 'tr' },
			jsPDF: { unit: 'cm', format: 'A4', orientation: 'portrait' }
		};

		html2pdf()
			.from(element)
			.set(opt)
			.toPdf()
			.get('pdf')
			.then((pdf) => {

				let totalPages = pdf.internal.getNumberOfPages();

				for (let i = 1; i <= totalPages; i++) {
					pdf.setPage(i);
					pdf.setFontSize(8);
					pdf.setTextColor(150);
					pdf.text(
						i + '/' + totalPages,
						pdf.internal.pageSize.getWidth() / 2 - 1,
						pdf.internal.pageSize.getHeight() - 0.8
					)
					pdf.setFontSize(10);
					pdf.text(
						'https://branddb.wipo.int/',
						pdf.internal.pageSize.getWidth() / 2 - 2.5,
						0.8
					)
					// addImage method https://artskydj.github.io/jsPDF/docs/module-addImage.html
					pdf.addImage(wipoImage, 'png', 0.5, 0.25, 4, 2)
				}
				// window.open(pdf.output('bloburl',{ filename: 'fsaf'}), '_blank');
			})
			.save()
			.then((pdf) => {
				window.parent.postMessage("export_done")
			})
	}

	async exportAsExcel() {

		const l = `exportAsExcel()`

		const workbook = new ExcelJS.Workbook();

		// Metadata
		workbook.creator = 'Me';
		workbook.lastModifiedBy = 'Her';
		workbook.created = new Date(1985, 8, 30);
		workbook.modified = new Date();
		workbook.lastPrinted = new Date(2016, 9, 27);

		// Header and footer
		const sheet = workbook.addWorksheet(this.fileName, {
			headerFooter: {
				firstHeader: "WIPO Header",
				firstFooter: "WIPO footer"
			}
		});

		// Columns names
		sheet.columns = [
			{ header: '', key: 'index', width: 5 },
			{ header: 'Brand name', key: 'brandName', width: 25 },
			{ header: 'Logo', key: 'logo', width: 40 },
			{ header: 'IPR', key: 'ipr', width: 30 },
			{ header: 'Designation', key: 'designation', width: 25 },
			{ header: 'Status', key: 'status', width: 25 },
			{ header: 'Status date', key: 'date', width: 40 },
			{ header: 'Number', key: 'number', width: 25 },
			{ header: 'Office', key: 'office', width: 25 },
			{ header: 'Nice classification', key: 'nice', width: 25 },
			{ header: 'Owner', key: 'applicant', width: 40 },
		];

		const columns = sheet.columns.map(c => c.key);

		let row: number = 1;

		for (let doc of this.docs) {

			const newRow = sheet.addRow({
				index: row,
				brandName: doc.human.brandName,
				logo: null,
				ipr: doc.human.ipr,
				designation: doc.human.designation,
				status: doc.human.status,
				statusDate: doc.human.date, // 2022-02-01
				number: doc.human.number,
				office: doc.human.office,
				nice: (doc.niceClass || []).join(", "),
				applicant: doc.human.applicant,
			})

			/*
				LOGO work : finding out image dimensions, ratio, etc.
			*/

			let logo: string = doc.logo && doc.logo[0];

			if (logo) {
				// the logo is always base64

				if (!logo.startsWith("data:image")) {
					logo = "data:image/jpeg;base64," + logo
				}

				const imageId = workbook.addImage({
					base64: logo,
					extension: 'jpeg',
				});

				let dimensions

				try {
					dimensions = await getImageDimensions(logo);
				} catch (err) {
					// console.log(`${l}Getting image dimensions error : `, err)
					continue
				}

				// resize to half size of the image
				sheet.addImage(imageId, {
					tl: { col: 2, row },
					ext: { width: dimensions.w / 2, height: dimensions.h / 2 }
				});

				newRow.height = Math.max(50, dimensions.h / 2 * 0.8);

			} else {
				newRow.height = 50;
			}

			newRow.getCell("brandName").style = { font: { bold: true, size: "14px" } };

			// Like, REALLY?? I have to select EACH cell ONE BY ONE and set their alignment ONE BY ONE??
			for (let c of columns) {
				newRow.getCell(c).alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
			}

			row++;
		}

		// WriteFile is not supported client-side
		// https://github.com/exceljs/exceljs/issues/460#issuecomment-619957534
		const buffer = await workbook.xlsx.writeBuffer();
		const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type });

		saveAs(blob, `${this.fileName}.xlsx`);
		window.parent.postMessage("export_done")
	}
}
