import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageHelpComponent } from './pages/page-help/page-help.component';

const routes: Routes = [

	{
		/*
			QUICK SEARCH
		*/
		path: `:lang/quicksearch`,
		loadChildren: async () => (await import("./tabs/1-quicksearch/page-quicksearch.module")).PageQuicksearchModule
	},
	{
		/*
			QUICK SEARCH - but only for a specific office
		*/
		path: `:lang/:office/quicksearch`,
		loadChildren: async () => (await import("./tabs/1-quicksearch/page-quicksearch.module")).PageQuicksearchModule
	},

	{
		/*
			SEARCH BY SIMILAR NAME
		*/
		path: ':lang/similarname',
		loadChildren: async () => (await import("./tabs/2-byName/page-similarname.module")).PageSimilarnameModule,
	},
	{
		/*
			SEARCH BY SIMILAR NAME - only for a specific office
		*/
		path: ':lang/:office/similarname',
		loadChildren: async () => (await import("./tabs/2-byName/page-similarname.module")).PageSimilarnameModule,
	},

	{
		/*
			SEARCH BY SIMILAR LOGO
		*/
		path: ':lang/similarlogo',
		loadChildren: async () => (await import("./tabs/3-byLogo/page-similarlogo.module")).PageSimilarlogoModule
	},
	{
		/*
			SEARCH BY SIMILAR LOGO - only for a specific office
		*/
		path: `:lang/:office/similarlogo`,
		loadChildren: async () => (await import("./tabs/3-byLogo/page-similarlogo.module")).PageSimilarlogoModule
	},

	{
		/*
			ADVANCED SEARCH PAGE
		*/
		path: `:lang/advancedsearch`,
		loadChildren: async () => (await import("./tabs/4-advancedSearch/page-advancedSearch.module")).PageQueryBuilderModule
	},
	{
		/*
			ADVANCED SEARCH PAGE (OFFICE)
		*/
		path: `:lang/:office/advancedsearch`,
		loadChildren: async () => (await import("./tabs/4-advancedSearch/page-advancedSearch.module")).PageQueryBuilderModule
	},
	{
		/*
			EXPLORE PAGE
		*/
		path: `:lang/explore`,
		loadChildren: async () => (await import("./tabs/5-explore/page-explore.module")).PageExploreModule
	},
	{
		/*
			EXPLORE PAGE - only for a specific office
		*/
		path: `:lang/:office/explore`,
		loadChildren: async () => (await import("./tabs/5-explore/page-explore.module")).PageExploreModule
	},

	{
		/*
			VISU
		*/
		path: `:lang/:endpoint/visu`,
		loadChildren: async () => (await import("./tabs/5bis-visu/page-visu.module")).PageVisuModule
	},
	{
		/*
			VISU - only for a specific office
		*/
		path: `:lang/:office/:endpoint/visu`,
		loadChildren: async () => (await import("./tabs/5bis-visu/page-visu.module")).PageVisuModule
	},

	{
		/*
			REPORTS PAGE
		*/
		path: `:lang/reports`,
		loadChildren: async () => (await import("./tabs/6-reports/page-reports.module")).PageReportsModule
	},
	{
		/*
			REPORTS PAGE
		*/
		path: `:lang/:office/reports`,
		loadChildren: async () => (await import("./tabs/6-reports/page-reports.module")).PageReportsModule
	},

	{
		/*
			COVERAGE PAGE
		*/
		path: `:lang/coverage`,
		loadChildren: async () => (await import("./tabs/7-coverage/page-coverage.module")).PageCoverageModule
	},
	{
		/*
			COVERAGE PAGE
		*/
		path: `:lang/:office/coverage`,
		loadChildren: async () => (await import("./tabs/7-coverage/page-coverage.module")).PageCoverageModule
	},

	{
		/*
			RESULTS PAGE
		*/
		path: `:lang/:endpoint/results`, // /en/quicksearch/results
		loadChildren: async () => (await import("./pages/page-results/page-results.module")).PageResultsModule
	},
	{
		/*
			RESULTS PAGE (OFFICE)
		*/
		path: `:lang/:office/:endpoint/results`, // /en/quicksearch/results
		loadChildren: async () => (await import("./pages/page-results/page-results.module")).PageResultsModule
	},

	{
		/*
			DETAILS PAGE without endpoint (when open in a new tab)
		*/
		path: ':lang/design/:st13',
		pathMatch: 'full',
		loadChildren: async () => (await import("./pages/page-details/page-details.module")).PageDetailsModule,
	},
	{
		/*
			DETAILS PAGE
		*/
		path: ':lang/:endpoint/design/:st13',
		loadChildren: async () => (await import("./pages/page-details/page-details.module")).PageDetailsModule,
	},
	{
		/*
			DETAILS PAGE (OFFICE)
		*/
		path: ':lang/:office/:endpoint/design/:st13',
		loadChildren: async () => (await import("./pages/page-details/page-details.module")).PageDetailsModule,
	},
	{
		/*
			HELP / DOCUMENTATION PAGE
		*/
		path: ':lang/help',
		component : PageHelpComponent,
	},

	{
		/*
			NEWS PAGE
		*/
		path: `:lang/news`,
		loadChildren: async () => (await import("./pages/page-news/page-news.module")).PageNewsModule
	},
	{
		/*
			EXPORTING search results to PDF or Excel (works for quicksearch, similarlogo, similarname, reports)
		*/
		path: `:lang/:endpoint/export`,
		loadChildren: async () => (await import("./pages/page-export/page-export.module")).PageExportModule
	},
	{
		/*
			EXPORTING search results to PDF or Excel (works for quicksearch, similarlogo, similarname, reports)
		*/
		path: `:lang/:office/:endpoint/export`,
		loadChildren: async () => (await import("./pages/page-export/page-export.module")).PageExportModule
	},
	
	{
		/*
			ERROR - NOT FOUND PAGE
		*/
		path: `:lang/notfound`,
		loadChildren: async () => (await import("./pages/page-notfound/page-notfound.module")).PageNotfoundModule
	},

	/*
		REDIRECTIONS AND DEFAULT ROUTES
	*/
	{ path: ':lang', redirectTo: ":lang/quicksearch", pathMatch: 'full' },
	{ path: ':lang/', redirectTo: ":lang/quicksearch", pathMatch: 'full' },
	{ path: '', redirectTo: "en/quicksearch", pathMatch: 'full' },
	{ path: 'logout', redirectTo: "en/quicksearch", pathMatch: 'full' },
	{
		path: '**',
		loadChildren: async () => (await import("./pages/page-notfound/page-notfound.module")).PageNotfoundModule
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		// enableTracing: true,
		// initialNavigation: 'disabled' // initial navigation happens after doSSO() in auth.service
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
