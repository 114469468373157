import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PaginationEvent } from 'src/app/interfaces';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { PreferencesService } from 'src/app/_services/preferences.service';
import { QueryParamsService } from 'src/app/_services/queryParams.service';
import { SearchService } from 'src/app/_services/search.service';

@Component({
	selector: 'results-list',
	templateUrl: './results-list.component.html',
	styleUrls: ['./results-list.component.css'],
	// encapsulation: ViewEncapsulation.None, No need, I think?
})
export class ResultsListComponent implements OnInit {

	@Input() docs: any[] = []
	public selectedThumbnailImgIndex:number[] = []
	constructor(
		public ms: MechanicsService,
		public ss: SearchService,
		public ps: PreferencesService,
		private qs: QueryParamsService) { }

	ngOnInit(): void {
		for(let i=0; i<this.docs.length;i++){
			this.selectedThumbnailImgIndex.push(0)
		}
	}

	onPagination(pageChangeEvent: PaginationEvent) {

		const l = `resultList onPAgination() - `

		/*
			Receiving : 
			{
				currentPage: 2,
				skip: 10
			}
	
			I need : 
				- &start=10 ( = skip)


			*/

		// console.log(`${l}pageChangeEvent = `, pageChangeEvent)

		this.qs.setQP("start", pageChangeEvent.skip)

		this.qs.queryParamsObjectToUrl()

	}


	onSelectAllChecked($event) {

		const l = `onSelectAllChecked() - `

		const isChecked: boolean = $event.target.checked

		for (let doc of this.docs) {
			doc.selected = isChecked
		}
	}

	get isAllSelected() {
		return this.docs?.every(doc => doc.selected)
	}

	details(doc, idx) {

		const l = `resultsComponent details() - `


		// console.log(`${l}idx=`, idx)


		let st13 = doc.st13
		console.log(this.ss.searchResult)
		this.ss.searchResult.setCursor(idx)
		// to be changes with next/prev buttons to force page reload
		// we do not change '_' here as it is being used as a query identifier
		this.qs.setQP('i', idx)

		const route = this.ms.makeRoute({ path: this.ms.endpoint, subpath: `design/${st13}`, caller: l })
		console.log(route)
		this.qs.queryParamsObjectToUrl(route)
	}

	hasLogo(result): boolean {

		const hasRegularLogo: boolean = result.logo && result.logo[0] && result.logo[0].length > 0;
		const hasDummyLogo = result.dummyLogo && result.dummyLogo.length > 0

		return hasRegularLogo || hasDummyLogo;
	}

	changeImage(event:number[]){
		this.selectedThumbnailImgIndex[event[1]] = event[0]
		console.log(this.selectedThumbnailImgIndex)
	}

}
